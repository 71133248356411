import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { getUnixTime } from 'date-fns';
import * as Sentry from '@sentry/react';
import { addBusinessDays, formatMarketTime, marketTimeToCorrectUnix, parseAgnosticStampToMarketUnix, parseAssumeMarketTime } from 'src/utils/datetime/date-fns.tz';
import TradingViewLayoutManager from 'src/services/TradingViewLayoutManager';
import { useDispatch } from 'react-redux';
import { deleteTemplate, fetchListTemplates, saveTemplate } from 'src/redux/tradingViewChart/tradingViewChartActions';
import useSelectorRef from 'src/hooks/useSelectorRef';
import LayoutButton from 'src/app/ChartingLibrary/Dialogs/EmbeddedToolbarButtons/LayoutButton';
import TemplateAndDropdownButton from 'src/app/ChartingLibrary/Dialogs/EmbeddedToolbarButtons/TemplateAndDropdownButton';
import { Widget, vendorPath } from 'src/app/ChartingLibrary/ChartingLibraryVersion';
import DataFeed from 'src/app/ChartingLibrary/scripts/DataFeed';
import TemplateDialog from './Dialogs/TemplateDialog';
import {
  makeStyles,
  Paper,
  useTheme,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.background.tvChart,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    '& iframe': {
      backgroundColor: theme.palette.background.tvChart,
    }
  },
  autosize: {
    flex: 1,
  },
  autosizePlaceholder: {
    backgroundColor: theme.palette.background.tvChart
  },
  chartCont: {
    display: 'flex',
    flex: '1',
    height: '100%',
    position: 'relative',
  }
}));


const resolutionConfig = {
  '1': '1D',
  '2': '2D',
  '3': '3D',
  '5': '5D',
  '10': '7D',
  '15': '10D',
  '30': '15D',
  '60': '20D',
  '1D': '7M',
  '1W': '12M',
  '1M': '12M'
};

const emptyObj = {};

function AutosavedChart({
  className,
  tickerId,
  componentId,
  clientId,
  fromDate,
  interval,
  isRealtime,
  forceMinuteBarsForFirstHistoricalDateSelect,
  selectedLayoutId,
  selectedTemplateId,
  onTemplateSelect,
  onIntervalChange
}) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [templates, templatesRef] = useSelectorRef(state => state.tvchart.templates[clientId]);
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
  const [chartReady, setChartReady] = useState(false);
  const chartEl = useRef();
  const chartWidget = useRef();
  const selectedTemplateIdRef = useRef(selectedTemplateId);
  const layoutButton = useRef();
  const templateButton = useRef();
  const registered = useRef(false);
  const hasManuallyChangedInterval = useRef(false);

  selectedTemplateIdRef.current = selectedTemplateId;

  const templateItem = templates.find(el => el.id === selectedTemplateId) || emptyObj;
  const templateItemName = templateItem?.name;

  const elementId = `tv_chart_${componentId}`;


  //******* WIDGET CONSTRUCTOR *******/


  const getWidgetConfig = () => {
    const _interval = interval || '1D';
    let dateObj = fromDate ? parseAssumeMarketTime(fromDate, 'yyyy-MM-dd') : null;

    const config = {
      symbol: tickerId,
      timezone: 'America/New_York',
      disabled_features: ['header_symbol_search', 'symbol_search_hot_key', 'compare_symbol', 'header_compare', 'timeframes_toolbar', 'use_localstorage_for_settings', 'save_chart_properties_to_local_storage', 'study_templates', 'header_saveload'],
      // disabled_features: ['header_symbol_search', 'symbol_search_hot_key', 'compare_symbol', 'header_compare', 'timeframes_toolbar'],
      enabled_features: ['side_toolbar_in_fullscreen_mode', 'header_in_fullscreen_mode', 'pre_post_market_sessions'],
      fullscreen: false,
      container: elementId,
      library_path: vendorPath,
      custom_css_url: `/static/css/tv_chart.v24.css`,
      width: '100%',
      autosize: true,
      auto_save_delay: 0.1,
      theme: 'Dark',
      toolbar_bg: theme.palette.background.tvChart,
      loading_screen: { backgroundColor: theme.palette.background.tvChart, foregroundColor: theme.palette.background.tvChart },
      debug: false,
      // interval: '1D',
      // timeframe: '1M',
      interval: _interval,
      overrides: {
        'mainSeriesProperties.style': 0,
        'paneProperties.background': theme.palette.background.tvChart,
      },
      load_last_chart: false
    };

    if (forceMinuteBarsForFirstHistoricalDateSelect) {
      forceTimeframeAndInterval(config);
    }

    if (['1', '2', '3', '5', '10', '15', '30', '60'].includes(config.interval) && dateObj) {
      dateObj = addBusinessDays(dateObj, 1);
    }

    // dateObj has correct time, but wrong UNIX timestamp. Convert.
    const initialDateUnixSeconds = dateObj ? getUnixTime(marketTimeToCorrectUnix(dateObj)) : null;

    config.datafeed = new DataFeed(componentId, initialDateUnixSeconds, resolutionConfig, isRealtime);
    return config;
  };


  const forceTimeframeAndInterval = (config) => {
    // Used to have dateObj passed in. Why?
    if (fromDate) {
      if (!hasManuallyChangedInterval.current) {
        config.interval = '1';
        config.timeframe = resolutionConfig[config.interval] || '1M';
      } else if (interval) {
        config.interval = interval;
        config.timeframe = resolutionConfig[interval] || '1M';
      }
    } else if (interval) {
      config.interval = interval;
      config.timeframe = resolutionConfig[interval] || '1M';
    }
  };


  const createChartWidget = async (mounted) => {
    if (!mounted) return;

    setChartReady(false);
    const config = getWidgetConfig();

    try {
      const defaultLayout = await TradingViewLayoutManager.fetchLayout(componentId, clientId, selectedLayoutId);
      if (defaultLayout && defaultLayout?.content) {
        config.saved_data = defaultLayout.content;
      }
    } catch (err) {
      Sentry.captureException(err, { extra: 'Chart layout failed to fetch from server. Ignoring and showing default.' });
    }

    try {
      if (!mounted) return;
      chartWidget.current = new Widget(config);
    } catch (err) {
      console.error(err);
      Sentry.withScope(scope => {
        scope.setExtra('componentId', componentId);
        scope.setExtra('config', config);
        Sentry.captureException(err)
      });
      return null;
    }

    if (!mounted) return;

    setChartReady(true);
    chartWidget.current.headerReady().then(() => createHeaderButtons(chartWidget));
    chartWidget.current.onChartReady(() => {
      chartWidget.current.activeChart().setChartType(1);

      if (onIntervalChange) {
        chartWidget.current.activeChart().onIntervalChanged().subscribe(null, () => {
          const newInterval = chartWidget.current.activeChart().resolution();
          onIntervalChange(newInterval);
          if (fromDate) {
            hasManuallyChangedInterval.current = true;
          }
        });
      }

      TradingViewLayoutManager.registerWidget(componentId, chartWidget);
    });

    return chartWidget.current;
  };


  //******* EFFECTS *******/

  // Allow the manager to understand Templates and Layouts
  useEffect(() => {
    TradingViewLayoutManager.registerComponent(componentId, clientId, selectedLayoutId, selectedTemplateId, tickerId, interval);
    registered.current = true;
  }, [clientId, selectedLayoutId, selectedTemplateId, tickerId, interval, chartWidget.current]);


  // Create new widget if the date changes
  useEffect(() => {
    let mounted = true;

    if (!registered.current) return;
    if (!tickerId) return;
    void createChartWidget(mounted);
    return () => mounted = false;
  }, [fromDate, registered.current]);


  // Change ticker symbol without re-initializing. (Unless first load, and ticker resolves before fromDate)
  useEffect(() => {
    let mounted = true;

    if (!registered.current) return;
    if (tickerId && !chartWidget.current) {
      void createChartWidget(mounted);
    } else if (tickerId && chartWidget.current) {
      chartWidget.current.onChartReady(() => {
        if (mounted) {
          chartWidget.current.activeChart().setSymbol(tickerId);
        }
      });
    }

    return () => mounted = false;
  }, [tickerId, registered.current]);


  // Set the template name on the embedded chart button
  useEffect(() => {
    if (templateItemName && templateButton.current) {
      templateButton.current.setName(templateItemName);
    }
  }, [templateItemName]);


  // Fetch/load the content. Putting this in redux doesn't make sense
  useEffect(() => {
    if (templateItem?.id && chartReady) {
      try {
        void TradingViewLayoutManager.fetchAndLoadTemplate(componentId, { widget: chartWidget });
      } catch (err) {
        console.error('Failed fetchAndLoadTemplate', err);
      }
    }
  }, [templateItem?.id, fromDate, chartReady]);


  // List server templates once on mount. Will be updated in-memory afterwords.
  // Unmount widget, layoutManager, and reset the barCache cursor for this component.
  useEffect(() => {
    dispatch(fetchListTemplates(clientId));

    return () => {
      TradingViewLayoutManager.unregesterComponent(componentId);
      if (chartWidget.current) {
        chartWidget.current._options.datafeed.resetCache();
        chartWidget.current.remove();
        chartWidget.current = null;
        setChartReady(false);
      }
    };
  }, []);


  //******* TEMPLATE HANDLERS *******/


  const handleOverwriteTemplate = () => {
    if (selectedTemplateIdRef.current && chartWidget.current) {
      chartWidget.current.onChartReady(() => {
        const templateData = chartWidget.current.activeChart().createStudyTemplate({ saveSymbol: false, saveInterval: false });
        TradingViewLayoutManager.saveTemplate(componentId, templateData);
      });
    }
  };


  const handleTemplateClose = () => setTemplateDialogOpen(false);


  const handleTemplateCreate = (name) => {
    if (chartWidget.current) {
      chartWidget.current.onChartReady(() => {
        const templateData = chartWidget.current.activeChart().createStudyTemplate({ saveSymbol: false, saveInterval: false });
        dispatch(saveTemplate(clientId, name, templateData));
      });
    }
  };


  const handleTemplateDelete = (id) => {
    dispatch(deleteTemplate(clientId, id));
  };


  const createHeaderButtons = (widget) => {
    const frozenTemplate = templatesRef.current.find(t => t.id === selectedTemplateIdRef.current);
    const templateName = frozenTemplate ? frozenTemplate.name : 'Templates';
    templateButton.current = new TemplateAndDropdownButton(widget, templateName, {},
      handleOverwriteTemplate,
      () => setTemplateDialogOpen(true)
    );

    layoutButton.current = new LayoutButton(widget, 'Settings', {});
    layoutButton.current.setAttribute('title', 'Save general chart Settings');
    layoutButton.current.onClick(() => TradingViewLayoutManager.manualSave(componentId, widget));
  };


  //*********** RENDER ***********//


  const renderChart = () => {
    return (
      <>
        <div ref={chartEl} id={elementId} style={{ height: '100%', flex: 1, backgroundColor: '#14151a', display: tickerId ? 'block' : 'none' }} className="tv_chart" />
        <div style={{ height: '100%', flex: 1, display: tickerId ? 'none' : 'block' }} className={classes.autosizePlaceholder} />
      </>
    )
    // return (tickerId)
    //   ? <div ref={chartEl} id={elementId} style={{ height: '100%', flex: 1, backgroundColor: '#14151a' }} className="tv_chart" />
    //   : <div style={{ height: '100%', flex: 1 }} className={classes.autosizePlaceholder} />;
  };


  return (
    <Paper elevation={1} className={clsx(className, classes.root, classes.autosize)}>
      <div className={clsx(classes.chartCont)}>
        {renderChart()}
        <TemplateDialog
          description={'Save and Load indicator templates'}
          templates={templates}
          selectedTemplateId={selectedTemplateId}
          open={templateDialogOpen}
          isFetching={false}
          onClose={handleTemplateClose}
          onSelect={onTemplateSelect}
          onDelete={handleTemplateDelete}
          onCreate={handleTemplateCreate}
        />
      </div>
    </Paper>
  );
}


AutosavedChart.propTypes = {
  className: PropTypes.string,
  tickerId: PropTypes.string,
  componentId: PropTypes.string,
  clientId: PropTypes.string,
  fromDate: PropTypes.string,
  interval: PropTypes.string,
  isRealtime: PropTypes.bool,
  forceMinuteBarsForFirstHistoricalDateSelect: PropTypes.bool,
  selectedLayoutId: PropTypes.string,
  selectedTemplateId: PropTypes.string,
  onTemplateSelect: PropTypes.func,
  onIntervalChange: PropTypes.func,
};


AutosavedChart.defaultProps = {
  componentId: 'tv_chart_container',
  clientId: 'overview',
  fromDate: null,
  isRealtime: false,
  forceMinuteBarsForFirstHistoricalDateSelect: false
};

export default AutosavedChart;



















