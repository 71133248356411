import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  DAY_LABELS,
  LABEL_SUPPLIMENTS,
  day0Cfg,
  day1Cfg
} from './labels';
import { fullFormatStatCellNumber } from 'src/utils/formatStatCellNumber';
import GapStatsBarChart from './GapStatsBarChart';
import TwoColDivTable from 'src/app/components/tables/TwoColDivTable';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 4,
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  column: {
    padding: '0 4px',
    flex: 1,
    minWidth: 220
  },
  columnLarge: {
    flexBasis: 390,
  },
  table: {
    marginTop: 8,
  '& .MuiTypography-h3': {
      padding: '0 0.3em 0.3em',
      paddingLeft: 6,
      fontSize: 17
    },
    '& .MuiTypography-body2': {
      whiteSpace: 'nowrap'
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      '& .makeStyles-cellRight-66': {
        textAlign: 'right !important'
      }
    }
  }
}));


// TODO: This is uneeded, we should change it to be flat array of metrics
const barChartSeriesConfig = [
  {
    name: 'PMH %',
    operation: (e) => e['day0_pm_high_pct']
  },
  {
    name: 'Gap %',
    operation: (e) => e['day0_gap_pct']
  },
  {
    name: 'High %',
    operation: (e) => e['day0_close_high_pct']
  },
  {
    name: 'Close %',
    operation: (e) => e['day0_chg_pct']
  },
];




function TopListGapStats({
  className,
  stats,
  records
}) {
  const classes = useStyles();


  const mapTableData = (cfg) => {
    return cfg.map(({ metric, hardFormat, referenceKey }) => {
      let value = '';

      if (Array.isArray(metric)) {
        value += metric.map(m => fullFormatStatCellNumber(stats?.[m], {
          pre: LABEL_SUPPLIMENTS?.[m]?.pre,
          post: LABEL_SUPPLIMENTS?.[m]?.post,
          stripTrailingZeros: true,
          hardFormat
        })).join(' / ');
      } else {
        value = fullFormatStatCellNumber(stats?.[metric], {
          pre: LABEL_SUPPLIMENTS?.[metric]?.pre,
          post: LABEL_SUPPLIMENTS?.[metric]?.post,
          stripTrailingZeros: true,
          hardFormat
        });
      }

      return { label: DAY_LABELS[referenceKey], value };
    });
  };



  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <div className={classes.column}>
          <TwoColDivTable dense title={`Day 1`} data={mapTableData(day0Cfg)} className={classes.table} align={'right'}/>
        </div>
        <div className={classes.column}>
          <TwoColDivTable dense title={`Day 2`} data={mapTableData(day1Cfg)} className={classes.table} align={'right'}/>
        </div>
        <div className={clsx(classes.column, classes.columnLarge)}>
          <GapStatsBarChart timeSeriesData={records} operations={barChartSeriesConfig} height={199}/>
        </div>
      </div>
    </div>
  );
}


TopListGapStats.propTypes = {
  className: PropTypes.string,
  stats: PropTypes.object,
  records: PropTypes.array
}


TopListGapStats.defaultProps = {
  stats: {},
  records: []
}


export default TopListGapStats;

