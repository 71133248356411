import { renderTickerSSRCell } from 'src/app/components/grid/gridColumnFunctions';
import {
  ALLOW_NULL,
  DATA_TYPES,
  INPUTS,
  STICKY_OPTIONS,
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import {
  integerToBooleanOptions,
} from 'src/app/slicedForm/FilterForm/definitions/dropdownOptions';
import {
  BASE_SYNONYMS,
} from 'src/app/slicedForm/shared/schema/synonyms';
import {
  SchemaBuilder,
  validateColDefs
} from 'src/app/slicedForm/shared/schema/schemaBuilder';
import { getColumnDefsFor, attachSynonymsToColumns } from '../../buildColumns';
import { TOP_LIST_ROLLING_DATES } from 'src/app/components/pickers/definitions/staticRangeDefinitions';
import { integerToBooleanFormatter } from 'src/app/components/grid/gridColumnFunctions.js'



/** Visual groupings of columns on the frontend */
export const GROUPS = {
  DAY0_METRICS: {
    label: 'Day 1 Metrics',
    name: 'DAY0_METRICS',
  },
  DAY1_METRICS: {
    label: 'Day 1 Metrics',
    name: 'DAY1_METRICS',
  },
  HOURLY_METRICS: {
    label: 'Hourly Metrics',
    name: 'HOURLY_METRICS',
  }
};


const SCHEMA = new SchemaBuilder({ noTimeSlice: true });



export const ALL_COLUMNS = validateColDefs([
  SCHEMA.date({
    name: 'day0_date',
    label: 'D[1] Date',
    formLabel: 'Date Range',
    group: GROUPS.DAY0_METRICS.name,
    grid: {
      width: 96,
      pinned: 'left',
      suppressSizeToFit: true,
      suppressAutoSize: true,
    },
    filter: {
      sticky: STICKY_OPTIONS.STICKY,
      stickyIndex: 0,
      label: 'Date Range'
    },
    column: {
      sticky: STICKY_OPTIONS.STICKY,
      stickyIndex: 0,
      label: 'Date'
    },
    dateConfig: {
      disableFuture: true,
      disableWeekend: false,
      disableHoliday: false,
      rollingDates: TOP_LIST_ROLLING_DATES,
      defaultRollingDate: 'rolling_30_days',
      makeInputDates: undefined,
    }
  }),
  SCHEMA.price({
    name: 'day0_open',
    label: 'D[1] Open',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_high',
    label: 'D[1] High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_low',
    label: 'D[1] Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_close',
    label: 'D[1] Close',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_vol',
    label: 'D[1] Vol',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_vw',
    label: 'D[1] VW',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_range',
    label: 'D[1] Range',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_chg',
    label: 'D[1] Chg',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_chg_pct',
    label: 'D[1] Chg %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_chg2',
    label: 'D[1] Chg2',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_chg2_pct',
    label: 'D[1] Chg2 %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_chg3',
    label: 'D[1] Chg3',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_chg3_pct',
    label: 'D[1] Chg3 %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_chg4',
    label: 'D[1] Chg4',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_chg4_pct',
    label: 'D[1] Chg4 %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_chg5',
    label: 'D[1] Chg5',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_chg5_pct',
    label: 'D[1] Chg5 %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_gap',
    label: 'D[1] Gap',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_gap_pct',
    label: 'D[1] Gap %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_ret_open',
    label: 'D[1] Ret Open',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_ret_open_pct',
    label: 'D[1] Ret Open %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.multiSelect({
    name: 'day0_open_ssr',
    label: 'D[1] Open SSR',
    group: GROUPS.DAY0_METRICS.name,
    dtype: DATA_TYPES.STRING,
    options: integerToBooleanOptions,
    grid: {
      valueFormatter: integerToBooleanFormatter
    }
  }),
  SCHEMA.multiSelect({
    name: 'day0_close_ssr',
    label: 'D[1] Close SSR',
    group: GROUPS.DAY0_METRICS.name,
    dtype: DATA_TYPES.STRING,
    options: integerToBooleanOptions,
    grid: {
      valueFormatter: integerToBooleanFormatter
    }
  }),
  SCHEMA.percentage({
    name: 'day0_open_high_pct',
    label: 'D[1] Open High %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_open_low_pct',
    label: 'D[1] Open Low %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_high_low_pct',
    label: 'D[1] High Low %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_close_high_pct',
    label: 'D[1] Close High %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_close_low_pct',
    label: 'D[1] Close Low %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_range_close_pct',
    label: 'D[1] Range Close %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_dollar_vol',
    label: 'D[1] Dollar Vol',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day0_consec_green',
    label: 'D[1] Consec Green',
    dtype: DATA_TYPES.INT,
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day0_consec_red',
    label: 'D[1] Consec Red',
    dtype: DATA_TYPES.INT,
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day0_consec_gap_up',
    label: 'D[1] Consec Gap Up',
    dtype: DATA_TYPES.INT,
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day0_consec_gap_down',
    label: 'D[1] Consec Gap Down',
    dtype: DATA_TYPES.INT,
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_open',
    label: 'D[2] Open',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_high',
    label: 'D[2] High',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_low',
    label: 'D[2] Low',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_close',
    label: 'D[2] Close',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day1_vol',
    label: 'D[2] Vol',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_vw',
    label: 'D[2] VW',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_range',
    label: 'D[2] Range',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_chg',
    label: 'D[2] Chg',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_chg_pct',
    label: 'D[2] Chg %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day1_gap',
    label: 'D[2] Gap',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_gap_pct',
    label: 'D[2] Gap %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day1_ret_open',
    label: 'D[2] Ret Open',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_ret_open_pct',
    label: 'D[2] Ret Open %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_high_low_pct',
    label: 'D[2] High Low %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day1_dollar_vol',
    label: 'D[2] Dollar Vol',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.multiSelect({
    name: 'day1_open_ssr',
    label: 'D[2] Open SSR',
    group: GROUPS.DAY1_METRICS.name,
    dtype: DATA_TYPES.STRING,
    options: integerToBooleanOptions,
    grid: {
      valueFormatter: integerToBooleanFormatter
    }
  }),
  SCHEMA.volume({
    name: 'day0_pm_vol',
    label: 'D[1] PM Vol',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_high',
    label: 'D[1] PM High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_high_time',
    label: 'D[1] PM High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_pm_high_pct',
    label: 'D[1] PM High %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_pm_vol_to_hod',
    label: 'D[1] PM Vol To Hod',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_low',
    label: 'D[1] PM Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_low_time',
    label: 'D[1] PM Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_pm_vol_to_lod',
    label: 'D[1] PM Vol To Lod',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_low_after_high',
    label: 'D[1] PM Low After High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_low_after_high_time',
    label: 'D[1] PM Low After High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_low_before_high',
    label: 'D[1] PM Low Before High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_low_before_high_time',
    label: 'D[1] PM Low Before High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_high_after_low',
    label: 'D[1] PM High After Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_high_after_low_time',
    label: 'D[1] PM High After Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_high_before_low',
    label: 'D[1] PM High Before Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_high_before_low_time',
    label: 'D[1] PM High Before Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_start_time',
    label: 'D[1] PM Start Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_pm_open_range_pct',
    label: 'D[1] PM Open Range %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_pm_pullback_open_j_pct',
    label: 'D[1] PM Pullback Open %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_high_time',
    label: 'D[1] High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_vol_to_hod',
    label: 'D[1] Vol To Hod',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_low_time',
    label: 'D[1] Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_vol_to_lod',
    label: 'D[1] Vol To Lod',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_low_after_high',
    label: 'D[1] Low After High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_low_after_high_time',
    label: 'D[1] Low After High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_low_before_high',
    label: 'D[1] Low Before High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_low_before_high_time',
    label: 'D[1] Low Before High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_high_after_low',
    label: 'D[1] High After Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_high_after_low_time',
    label: 'D[1] High After Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_high_before_low',
    label: 'D[1] High Before Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_high_before_low_time',
    label: 'D[1] High Before Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_vol',
    label: 'D[1] AH Vol',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_high',
    label: 'D[1] AH High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_high_time',
    label: 'D[1] AH High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_vol_to_hod',
    label: 'D[1] AH Vol To Hod',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_low',
    label: 'D[1] AH Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_low_time',
    label: 'D[1] AH Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_vol_to_lod',
    label: 'D[1] AH Vol To Lod',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_close',
    label: 'D[1] AH Close',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_chg',
    label: 'D[1] AH Chg',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_ah_chg_pct',
    label: 'D[1] AH Chg %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_ah_low_after_high',
    label: 'D[1] AH Low After High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_low_after_high_time',
    label: 'D[1] AH Low After High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_low_before_high',
    label: 'D[1] AH Low Before High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_low_before_high_time',
    label: 'D[1] AH Low Before High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_high_after_low',
    label: 'D[1] AH High After Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_high_after_low_time',
    label: 'D[1] AH High After Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_high_before_low',
    label: 'D[1] AH High Before Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_high_before_low_time',
    label: 'D[1] AH High Before Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_pm_high',
    label: 'D[2] PM High',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day1_pm_high_time',
    label: 'D[2] PM High Time',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day1_pm_high_pct',
    label: 'D[2] PM High %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day1_pm_low',
    label: 'D[2] PM Low',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day1_pm_low_time',
    label: 'D[2] PM Low Time',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day1_pm_vol',
    label: 'D[2] PM Vol',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day1_open_high_pct',
    label: 'D[2] Open High %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_open_low_pct',
    label: 'D[2] Open Low %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_close_high_pct',
    label: 'D[2] Close High %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_close_low_pct',
    label: 'D[2] Close Low %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_range_close_pct',
    label: 'D[2] Range Close %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day1_high_time',
    label: 'D[2] High Time',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day1_low_time',
    label: 'D[2] Low Time',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day1_vol_to_hod',
    label: 'D[2] Vol To Hod',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day1_vol_to_lod',
    label: 'D[2] Vol To Lod',
    group: GROUPS.DAY1_METRICS.name,
  }),

  SCHEMA.price({
    name: 'day0_spike_5min_max',
    label: 'D[1] Spike 5Min Max',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_spike_5min_max_pct',
    label: 'D[1] Spike 5Min Max %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_spike_5min_max_time',
    label: 'D[1] Spike 5Min Max Time',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_spike_5min_max_vol',
    label: 'D[1] Spike 5Min Max Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_5min_max_vw',
    label: 'D[1] Spike 5Min Max VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_5min_min',
    label: 'D[1] Drop 5Min Min',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_drop_5min_min_pct',
    label: 'D[1] Drop 5Min Min %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_drop_5min_min_time',
    label: 'D[1] Drop 5Min Min Time',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_drop_5min_min_vol',
    label: 'D[1] Drop 5Min Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_5min_min_vw',
    label: 'D[1] Drop 5Min Min VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_10min_max',
    label: 'D[1] Spike 10Min Max',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_spike_10min_max_pct',
    label: 'D[1] Spike 10Min Max %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_spike_10min_max_time',
    label: 'D[1] Spike 10Min Max Time',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_spike_10min_max_vol',
    label: 'D[1] Spike 10Min Max Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_10min_max_vw',
    label: 'D[1] Spike 10Min Max VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_10min_min',
    label: 'D[1] Drop 10Min Min',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_drop_10min_min_pct',
    label: 'D[1] Drop 10Min Min %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_drop_10min_min_time',
    label: 'D[1] Drop 10Min Min Time',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_drop_10min_min_vol',
    label: 'D[1] Drop 10Min Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_10min_min_vw',
    label: 'D[1] Drop 10Min Min VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_15min_max',
    label: 'D[1] Spike 15Min Max',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_spike_15min_max_pct',
    label: 'D[1] Spike 15Min Max %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_spike_15min_max_time',
    label: 'D[1] Spike 15Min Max Time',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_spike_15min_max_vol',
    label: 'D[1] Spike 15Min Max Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_15min_max_vw',
    label: 'D[1] Spike 15Min Max VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_15min_min',
    label: 'D[1] Drop 15Min Min',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_drop_15min_min_pct',
    label: 'D[1] Drop 15Min Min %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_drop_15min_min_time',
    label: 'D[1] Drop 15Min Min Time',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_drop_15min_min_vol',
    label: 'D[1] Drop 15Min Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_15min_min_vw',
    label: 'D[1] Drop 15Min Min VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_30min_max',
    label: 'D[1] Spike 30Min Max',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_spike_30min_max_pct',
    label: 'D[1] Spike 30Min Max %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_spike_30min_max_time',
    label: 'D[1] Spike 30Min Max Time',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_spike_30min_max_vol',
    label: 'D[1] Spike 30Min Max Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_30min_max_vw',
    label: 'D[1] Spike 30Min Max VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_30min_min',
    label: 'D[1] Drop 30Min Min',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_drop_30min_min_pct',
    label: 'D[1] Drop 30Min Min %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_drop_30min_min_time',
    label: 'D[1] Drop 30Min Min Time',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_drop_30min_min_vol',
    label: 'D[1] Drop 30Min Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_30min_min_vw',
    label: 'D[1] Drop 30Min Min VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_60min_max',
    label: 'D[1] Spike 60Min Max',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_spike_60min_max_pct',
    label: 'D[1] Spike 60Min Max %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_spike_60min_max_time',
    label: 'D[1] Spike 60Min Max Time',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_spike_60min_max_vol',
    label: 'D[1] Spike 60Min Max Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_60min_max_vw',
    label: 'D[1] Spike 60Min Max VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_60min_min',
    label: 'D[1] Drop 60Min Min',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_drop_60min_min_pct',
    label: 'D[1] Drop 60Min Min %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_drop_60min_min_time',
    label: 'D[1] Drop 60Min Min Time',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_drop_60min_min_vol',
    label: 'D[1] Drop 60Min Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_60min_min_vw',
    label: 'D[1] Drop 60Min Min VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0430_open',
    label: 'D[1] H 400 430 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0430_high',
    label: 'D[1] H 400 430 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0430_low',
    label: 'D[1] H 400 430 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0430_close',
    label: 'D[1] H 400 430 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0400_0430_vol',
    label: 'D[1] H 400 430 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0430_vw',
    label: 'D[1] H 400 430 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0430_chg',
    label: 'D[1] H 400 430 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0400_0430_chg_pct',
    label: 'D[1] H 400 430 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0500_open',
    label: 'D[1] H 400 500 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0500_high',
    label: 'D[1] H 400 500 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0500_low',
    label: 'D[1] H 400 500 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0500_close',
    label: 'D[1] H 400 500 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0400_0500_vol',
    label: 'D[1] H 400 500 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0500_vw',
    label: 'D[1] H 400 500 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0500_chg',
    label: 'D[1] H 400 500 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0400_0500_chg_pct',
    label: 'D[1] H 400 500 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0430_0500_open',
    label: 'D[1] H 430 500 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0430_0500_high',
    label: 'D[1] H 430 500 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0430_0500_low',
    label: 'D[1] H 430 500 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0430_0500_close',
    label: 'D[1] H 430 500 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0430_0500_vol',
    label: 'D[1] H 430 500 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0430_0500_vw',
    label: 'D[1] H 430 500 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0430_0500_chg',
    label: 'D[1] H 430 500 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0430_0500_chg_pct',
    label: 'D[1] H 430 500 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0530_open',
    label: 'D[1] H 500 530 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0530_high',
    label: 'D[1] H 500 530 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0530_low',
    label: 'D[1] H 500 530 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0530_close',
    label: 'D[1] H 500 530 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0500_0530_vol',
    label: 'D[1] H 500 530 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0530_vw',
    label: 'D[1] H 500 530 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0530_chg',
    label: 'D[1] H 500 530 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0500_0530_chg_pct',
    label: 'D[1] H 500 530 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0600_open',
    label: 'D[1] H 500 600 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0600_high',
    label: 'D[1] H 500 600 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0600_low',
    label: 'D[1] H 500 600 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0600_close',
    label: 'D[1] H 500 600 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0500_0600_vol',
    label: 'D[1] H 500 600 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0600_vw',
    label: 'D[1] H 500 600 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0600_chg',
    label: 'D[1] H 500 600 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0500_0600_chg_pct',
    label: 'D[1] H 500 600 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0530_0600_open',
    label: 'D[1] H 530 600 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0530_0600_high',
    label: 'D[1] H 530 600 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0530_0600_low',
    label: 'D[1] H 530 600 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0530_0600_close',
    label: 'D[1] H 530 600 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0530_0600_vol',
    label: 'D[1] H 530 600 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0530_0600_vw',
    label: 'D[1] H 530 600 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0530_0600_chg',
    label: 'D[1] H 530 600 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0530_0600_chg_pct',
    label: 'D[1] H 530 600 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0630_open',
    label: 'D[1] H 600 630 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0630_high',
    label: 'D[1] H 600 630 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0630_low',
    label: 'D[1] H 600 630 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0630_close',
    label: 'D[1] H 600 630 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0600_0630_vol',
    label: 'D[1] H 600 630 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0630_vw',
    label: 'D[1] H 600 630 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0630_chg',
    label: 'D[1] H 600 630 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0600_0630_chg_pct',
    label: 'D[1] H 600 630 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0700_open',
    label: 'D[1] H 600 700 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0700_high',
    label: 'D[1] H 600 700 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0700_low',
    label: 'D[1] H 600 700 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0700_close',
    label: 'D[1] H 600 700 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0600_0700_vol',
    label: 'D[1] H 600 700 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0700_vw',
    label: 'D[1] H 600 700 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0700_chg',
    label: 'D[1] H 600 700 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0600_0700_chg_pct',
    label: 'D[1] H 600 700 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0630_0700_open',
    label: 'D[1] H 630 700 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0630_0700_high',
    label: 'D[1] H 630 700 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0630_0700_low',
    label: 'D[1] H 630 700 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0630_0700_close',
    label: 'D[1] H 630 700 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0630_0700_vol',
    label: 'D[1] H 630 700 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0630_0700_vw',
    label: 'D[1] H 630 700 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0630_0700_chg',
    label: 'D[1] H 630 700 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0630_0700_chg_pct',
    label: 'D[1] H 630 700 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0730_open',
    label: 'D[1] H 700 730 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0730_high',
    label: 'D[1] H 700 730 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0730_low',
    label: 'D[1] H 700 730 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0730_close',
    label: 'D[1] H 700 730 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0700_0730_vol',
    label: 'D[1] H 700 730 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0730_vw',
    label: 'D[1] H 700 730 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0730_chg',
    label: 'D[1] H 700 730 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0700_0730_chg_pct',
    label: 'D[1] H 700 730 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0800_open',
    label: 'D[1] H 700 800 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0800_high',
    label: 'D[1] H 700 800 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0800_low',
    label: 'D[1] H 700 800 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0800_close',
    label: 'D[1] H 700 800 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0700_0800_vol',
    label: 'D[1] H 700 800 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0800_vw',
    label: 'D[1] H 700 800 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0800_chg',
    label: 'D[1] H 700 800 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0700_0800_chg_pct',
    label: 'D[1] H 700 800 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0730_0800_open',
    label: 'D[1] H 730 800 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0730_0800_high',
    label: 'D[1] H 730 800 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0730_0800_low',
    label: 'D[1] H 730 800 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0730_0800_close',
    label: 'D[1] H 730 800 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0730_0800_vol',
    label: 'D[1] H 730 800 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0730_0800_vw',
    label: 'D[1] H 730 800 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0730_0800_chg',
    label: 'D[1] H 730 800 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0730_0800_chg_pct',
    label: 'D[1] H 730 800 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0830_open',
    label: 'D[1] H 800 830 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0830_high',
    label: 'D[1] H 800 830 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0830_low',
    label: 'D[1] H 800 830 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0830_close',
    label: 'D[1] H 800 830 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0800_0830_vol',
    label: 'D[1] H 800 830 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0830_vw',
    label: 'D[1] H 800 830 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0830_chg',
    label: 'D[1] H 800 830 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0800_0830_chg_pct',
    label: 'D[1] H 800 830 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0900_open',
    label: 'D[1] H 800 900 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0900_high',
    label: 'D[1] H 800 900 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0900_low',
    label: 'D[1] H 800 900 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0900_close',
    label: 'D[1] H 800 900 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0800_0900_vol',
    label: 'D[1] H 800 900 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0900_vw',
    label: 'D[1] H 800 900 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0900_chg',
    label: 'D[1] H 800 900 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0800_0900_chg_pct',
    label: 'D[1] H 800 900 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0830_0900_open',
    label: 'D[1] H 830 900 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0830_0900_high',
    label: 'D[1] H 830 900 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0830_0900_low',
    label: 'D[1] H 830 900 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0830_0900_close',
    label: 'D[1] H 830 900 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0830_0900_vol',
    label: 'D[1] H 830 900 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0830_0900_vw',
    label: 'D[1] H 830 900 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0830_0900_chg',
    label: 'D[1] H 830 900 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0830_0900_chg_pct',
    label: 'D[1] H 830 900 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_0930_open',
    label: 'D[1] H 900 930 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_0930_high',
    label: 'D[1] H 900 930 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_0930_low',
    label: 'D[1] H 900 930 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_0930_close',
    label: 'D[1] H 900 930 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0900_0930_vol',
    label: 'D[1] H 900 930 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_0930_vw',
    label: 'D[1] H 900 930 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_0930_chg',
    label: 'D[1] H 900 930 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0900_0930_chg_pct',
    label: 'D[1] H 900 930 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_1000_open',
    label: 'D[1] H 900 1000 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_1000_high',
    label: 'D[1] H 900 1000 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_1000_low',
    label: 'D[1] H 900 1000 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_1000_close',
    label: 'D[1] H 900 1000 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0900_1000_vol',
    label: 'D[1] H 900 1000 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_1000_vw',
    label: 'D[1] H 900 1000 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_1000_chg',
    label: 'D[1] H 900 1000 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0900_1000_chg_pct',
    label: 'D[1] H 900 1000 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0930_1000_open',
    label: 'D[1] H 930 1000 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0930_1000_high',
    label: 'D[1] H 930 1000 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0930_1000_low',
    label: 'D[1] H 930 1000 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0930_1000_close',
    label: 'D[1] H 930 1000 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0930_1000_vol',
    label: 'D[1] H 930 1000 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0930_1000_vw',
    label: 'D[1] H 930 1000 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0930_1000_chg',
    label: 'D[1] H 930 1000 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0930_1000_chg_pct',
    label: 'D[1] H 930 1000 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1030_open',
    label: 'D[1] H 1000 1030 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1030_high',
    label: 'D[1] H 1000 1030 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1030_low',
    label: 'D[1] H 1000 1030 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1030_close',
    label: 'D[1] H 1000 1030 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1000_1030_vol',
    label: 'D[1] H 1000 1030 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1030_vw',
    label: 'D[1] H 1000 1030 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1030_chg',
    label: 'D[1] H 1000 1030 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1000_1030_chg_pct',
    label: 'D[1] H 1000 1030 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1100_open',
    label: 'D[1] H 1000 1100 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1100_high',
    label: 'D[1] H 1000 1100 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1100_low',
    label: 'D[1] H 1000 1100 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1100_close',
    label: 'D[1] H 1000 1100 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1000_1100_vol',
    label: 'D[1] H 1000 1100 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1100_vw',
    label: 'D[1] H 1000 1100 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1100_chg',
    label: 'D[1] H 1000 1100 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1000_1100_chg_pct',
    label: 'D[1] H 1000 1100 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1030_1100_open',
    label: 'D[1] H 1030 1100 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1030_1100_high',
    label: 'D[1] H 1030 1100 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1030_1100_low',
    label: 'D[1] H 1030 1100 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1030_1100_close',
    label: 'D[1] H 1030 1100 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1030_1100_vol',
    label: 'D[1] H 1030 1100 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1030_1100_vw',
    label: 'D[1] H 1030 1100 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1030_1100_chg',
    label: 'D[1] H 1030 1100 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1030_1100_chg_pct',
    label: 'D[1] H 1030 1100 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1130_open',
    label: 'D[1] H 1100 1130 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1130_high',
    label: 'D[1] H 1100 1130 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1130_low',
    label: 'D[1] H 1100 1130 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1130_close',
    label: 'D[1] H 1100 1130 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1100_1130_vol',
    label: 'D[1] H 1100 1130 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1130_vw',
    label: 'D[1] H 1100 1130 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1130_chg',
    label: 'D[1] H 1100 1130 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1100_1130_chg_pct',
    label: 'D[1] H 1100 1130 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1200_open',
    label: 'D[1] H 1100 1200 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1200_high',
    label: 'D[1] H 1100 1200 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1200_low',
    label: 'D[1] H 1100 1200 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1200_close',
    label: 'D[1] H 1100 1200 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1100_1200_vol',
    label: 'D[1] H 1100 1200 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1200_vw',
    label: 'D[1] H 1100 1200 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1200_chg',
    label: 'D[1] H 1100 1200 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1100_1200_chg_pct',
    label: 'D[1] H 1100 1200 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1130_1200_open',
    label: 'D[1] H 1130 1200 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1130_1200_high',
    label: 'D[1] H 1130 1200 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1130_1200_low',
    label: 'D[1] H 1130 1200 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1130_1200_close',
    label: 'D[1] H 1130 1200 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1130_1200_vol',
    label: 'D[1] H 1130 1200 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1130_1200_vw',
    label: 'D[1] H 1130 1200 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1130_1200_chg',
    label: 'D[1] H 1130 1200 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1130_1200_chg_pct',
    label: 'D[1] H 1130 1200 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1230_open',
    label: 'D[1] H 1200 1230 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1230_high',
    label: 'D[1] H 1200 1230 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1230_low',
    label: 'D[1] H 1200 1230 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1230_close',
    label: 'D[1] H 1200 1230 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1200_1230_vol',
    label: 'D[1] H 1200 1230 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1230_vw',
    label: 'D[1] H 1200 1230 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1230_chg',
    label: 'D[1] H 1200 1230 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1200_1230_chg_pct',
    label: 'D[1] H 1200 1230 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1300_open',
    label: 'D[1] H 1200 1300 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1300_high',
    label: 'D[1] H 1200 1300 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1300_low',
    label: 'D[1] H 1200 1300 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1300_close',
    label: 'D[1] H 1200 1300 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1200_1300_vol',
    label: 'D[1] H 1200 1300 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1300_vw',
    label: 'D[1] H 1200 1300 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1300_chg',
    label: 'D[1] H 1200 1300 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1200_1300_chg_pct',
    label: 'D[1] H 1200 1300 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1230_1300_open',
    label: 'D[1] H 1230 1300 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1230_1300_high',
    label: 'D[1] H 1230 1300 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1230_1300_low',
    label: 'D[1] H 1230 1300 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1230_1300_close',
    label: 'D[1] H 1230 1300 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1230_1300_vol',
    label: 'D[1] H 1230 1300 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1230_1300_vw',
    label: 'D[1] H 1230 1300 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1230_1300_chg',
    label: 'D[1] H 1230 1300 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1230_1300_chg_pct',
    label: 'D[1] H 1230 1300 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1330_open',
    label: 'D[1] H 1300 1330 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1330_high',
    label: 'D[1] H 1300 1330 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1330_low',
    label: 'D[1] H 1300 1330 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1330_close',
    label: 'D[1] H 1300 1330 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1300_1330_vol',
    label: 'D[1] H 1300 1330 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1330_vw',
    label: 'D[1] H 1300 1330 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1330_chg',
    label: 'D[1] H 1300 1330 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1300_1330_chg_pct',
    label: 'D[1] H 1300 1330 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1400_open',
    label: 'D[1] H 1300 1400 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1400_high',
    label: 'D[1] H 1300 1400 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1400_low',
    label: 'D[1] H 1300 1400 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1400_close',
    label: 'D[1] H 1300 1400 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1300_1400_vol',
    label: 'D[1] H 1300 1400 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1400_vw',
    label: 'D[1] H 1300 1400 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1400_chg',
    label: 'D[1] H 1300 1400 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1300_1400_chg_pct',
    label: 'D[1] H 1300 1400 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1330_1400_open',
    label: 'D[1] H 1330 1400 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1330_1400_high',
    label: 'D[1] H 1330 1400 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1330_1400_low',
    label: 'D[1] H 1330 1400 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1330_1400_close',
    label: 'D[1] H 1330 1400 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1330_1400_vol',
    label: 'D[1] H 1330 1400 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1330_1400_vw',
    label: 'D[1] H 1330 1400 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1330_1400_chg',
    label: 'D[1] H 1330 1400 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1330_1400_chg_pct',
    label: 'D[1] H 1330 1400 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1430_open',
    label: 'D[1] H 1400 1430 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1430_high',
    label: 'D[1] H 1400 1430 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1430_low',
    label: 'D[1] H 1400 1430 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1430_close',
    label: 'D[1] H 1400 1430 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1400_1430_vol',
    label: 'D[1] H 1400 1430 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1430_vw',
    label: 'D[1] H 1400 1430 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1430_chg',
    label: 'D[1] H 1400 1430 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1400_1430_chg_pct',
    label: 'D[1] H 1400 1430 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1500_open',
    label: 'D[1] H 1400 1500 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1500_high',
    label: 'D[1] H 1400 1500 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1500_low',
    label: 'D[1] H 1400 1500 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1500_close',
    label: 'D[1] H 1400 1500 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1400_1500_vol',
    label: 'D[1] H 1400 1500 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1500_vw',
    label: 'D[1] H 1400 1500 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1500_chg',
    label: 'D[1] H 1400 1500 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1400_1500_chg_pct',
    label: 'D[1] H 1400 1500 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1430_1500_open',
    label: 'D[1] H 1430 1500 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1430_1500_high',
    label: 'D[1] H 1430 1500 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1430_1500_low',
    label: 'D[1] H 1430 1500 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1430_1500_close',
    label: 'D[1] H 1430 1500 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1430_1500_vol',
    label: 'D[1] H 1430 1500 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1430_1500_vw',
    label: 'D[1] H 1430 1500 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1430_1500_chg',
    label: 'D[1] H 1430 1500 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1430_1500_chg_pct',
    label: 'D[1] H 1430 1500 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1530_open',
    label: 'D[1] H 1500 1530 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1530_high',
    label: 'D[1] H 1500 1530 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1530_low',
    label: 'D[1] H 1500 1530 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1530_close',
    label: 'D[1] H 1500 1530 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1500_1530_vol',
    label: 'D[1] H 1500 1530 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1530_vw',
    label: 'D[1] H 1500 1530 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1530_chg',
    label: 'D[1] H 1500 1530 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1500_1530_chg_pct',
    label: 'D[1] H 1500 1530 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1600_open',
    label: 'D[1] H 1500 1600 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1600_high',
    label: 'D[1] H 1500 1600 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1600_low',
    label: 'D[1] H 1500 1600 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1600_close',
    label: 'D[1] H 1500 1600 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1500_1600_vol',
    label: 'D[1] H 1500 1600 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1600_vw',
    label: 'D[1] H 1500 1600 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1600_chg',
    label: 'D[1] H 1500 1600 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1500_1600_chg_pct',
    label: 'D[1] H 1500 1600 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1530_1600_open',
    label: 'D[1] H 1530 1600 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1530_1600_high',
    label: 'D[1] H 1530 1600 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1530_1600_low',
    label: 'D[1] H 1530 1600 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1530_1600_close',
    label: 'D[1] H 1530 1600 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1530_1600_vol',
    label: 'D[1] H 1530 1600 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1530_1600_vw',
    label: 'D[1] H 1530 1600 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1530_1600_chg',
    label: 'D[1] H 1530 1600 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1530_1600_chg_pct',
    label: 'D[1] H 1530 1600 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1630_open',
    label: 'D[1] H 1600 1630 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1630_high',
    label: 'D[1] H 1600 1630 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1630_low',
    label: 'D[1] H 1600 1630 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1630_close',
    label: 'D[1] H 1600 1630 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1600_1630_vol',
    label: 'D[1] H 1600 1630 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1630_vw',
    label: 'D[1] H 1600 1630 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1630_chg',
    label: 'D[1] H 1600 1630 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1600_1630_chg_pct',
    label: 'D[1] H 1600 1630 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1700_open',
    label: 'D[1] H 1600 1700 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1700_high',
    label: 'D[1] H 1600 1700 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1700_low',
    label: 'D[1] H 1600 1700 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1700_close',
    label: 'D[1] H 1600 1700 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1600_1700_vol',
    label: 'D[1] H 1600 1700 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1700_vw',
    label: 'D[1] H 1600 1700 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1700_chg',
    label: 'D[1] H 1600 1700 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1600_1700_chg_pct',
    label: 'D[1] H 1600 1700 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1630_1700_open',
    label: 'D[1] H 1630 1700 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1630_1700_high',
    label: 'D[1] H 1630 1700 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1630_1700_low',
    label: 'D[1] H 1630 1700 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1630_1700_close',
    label: 'D[1] H 1630 1700 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1630_1700_vol',
    label: 'D[1] H 1630 1700 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1630_1700_vw',
    label: 'D[1] H 1630 1700 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1630_1700_chg',
    label: 'D[1] H 1630 1700 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1630_1700_chg_pct',
    label: 'D[1] H 1630 1700 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1730_open',
    label: 'D[1] H 1700 1730 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1730_high',
    label: 'D[1] H 1700 1730 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1730_low',
    label: 'D[1] H 1700 1730 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1730_close',
    label: 'D[1] H 1700 1730 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1700_1730_vol',
    label: 'D[1] H 1700 1730 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1730_vw',
    label: 'D[1] H 1700 1730 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1730_chg',
    label: 'D[1] H 1700 1730 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1700_1730_chg_pct',
    label: 'D[1] H 1700 1730 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1800_open',
    label: 'D[1] H 1700 1800 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1800_high',
    label: 'D[1] H 1700 1800 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1800_low',
    label: 'D[1] H 1700 1800 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1800_close',
    label: 'D[1] H 1700 1800 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1700_1800_vol',
    label: 'D[1] H 1700 1800 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1800_vw',
    label: 'D[1] H 1700 1800 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1800_chg',
    label: 'D[1] H 1700 1800 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1700_1800_chg_pct',
    label: 'D[1] H 1700 1800 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1730_1800_open',
    label: 'D[1] H 1730 1800 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1730_1800_high',
    label: 'D[1] H 1730 1800 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1730_1800_low',
    label: 'D[1] H 1730 1800 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1730_1800_close',
    label: 'D[1] H 1730 1800 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1730_1800_vol',
    label: 'D[1] H 1730 1800 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1730_1800_vw',
    label: 'D[1] H 1730 1800 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1730_1800_chg',
    label: 'D[1] H 1730 1800 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1730_1800_chg_pct',
    label: 'D[1] H 1730 1800 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1830_open',
    label: 'D[1] H 1800 1830 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1830_high',
    label: 'D[1] H 1800 1830 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1830_low',
    label: 'D[1] H 1800 1830 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1830_close',
    label: 'D[1] H 1800 1830 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1800_1830_vol',
    label: 'D[1] H 1800 1830 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1830_vw',
    label: 'D[1] H 1800 1830 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1830_chg',
    label: 'D[1] H 1800 1830 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1800_1830_chg_pct',
    label: 'D[1] H 1800 1830 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1900_open',
    label: 'D[1] H 1800 1900 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1900_high',
    label: 'D[1] H 1800 1900 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1900_low',
    label: 'D[1] H 1800 1900 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1900_close',
    label: 'D[1] H 1800 1900 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1800_1900_vol',
    label: 'D[1] H 1800 1900 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1900_vw',
    label: 'D[1] H 1800 1900 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1900_chg',
    label: 'D[1] H 1800 1900 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1800_1900_chg_pct',
    label: 'D[1] H 1800 1900 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1830_1900_open',
    label: 'D[1] H 1830 1900 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1830_1900_high',
    label: 'D[1] H 1830 1900 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1830_1900_low',
    label: 'D[1] H 1830 1900 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1830_1900_close',
    label: 'D[1] H 1830 1900 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1830_1900_vol',
    label: 'D[1] H 1830 1900 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1830_1900_vw',
    label: 'D[1] H 1830 1900 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1830_1900_chg',
    label: 'D[1] H 1830 1900 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1830_1900_chg_pct',
    label: 'D[1] H 1830 1900 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_1930_open',
    label: 'D[1] H 1900 1930 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_1930_high',
    label: 'D[1] H 1900 1930 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_1930_low',
    label: 'D[1] H 1900 1930 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_1930_close',
    label: 'D[1] H 1900 1930 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1900_1930_vol',
    label: 'D[1] H 1900 1930 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_1930_vw',
    label: 'D[1] H 1900 1930 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_1930_chg',
    label: 'D[1] H 1900 1930 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1900_1930_chg_pct',
    label: 'D[1] H 1900 1930 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_2000_open',
    label: 'D[1] H 1900 2000 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_2000_high',
    label: 'D[1] H 1900 2000 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_2000_low',
    label: 'D[1] H 1900 2000 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_2000_close',
    label: 'D[1] H 1900 2000 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1900_2000_vol',
    label: 'D[1] H 1900 2000 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_2000_vw',
    label: 'D[1] H 1900 2000 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_2000_chg',
    label: 'D[1] H 1900 2000 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1900_2000_chg_pct',
    label: 'D[1] H 1900 2000 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1930_2000_open',
    label: 'D[1] H 1930 2000 Open',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1930_2000_high',
    label: 'D[1] H 1930 2000 High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1930_2000_low',
    label: 'D[1] H 1930 2000 Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1930_2000_close',
    label: 'D[1] H 1930 2000 Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1930_2000_vol',
    label: 'D[1] H 1930 2000 Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1930_2000_vw',
    label: 'D[1] H 1930 2000 VW',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1930_2000_chg',
    label: 'D[1] H 1930 2000 Chg',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1930_2000_chg_pct',
    label: 'D[1] H 1930 2000 Chg %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_1min_high',
    label: 'D[1] 1Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_1min_high_pct',
    label: 'D[1] 1Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_1min_low',
    label: 'D[1] 1Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_1min_low_pct',
    label: 'D[1] 1Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_1min_close',
    label: 'D[1] 1Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_1min_close_pct',
    label: 'D[1] 1Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_1min_vol',
    label: 'D[1] 1Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_2min_high',
    label: 'D[1] 2Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_2min_high_pct',
    label: 'D[1] 2Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_2min_low',
    label: 'D[1] 2Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_2min_low_pct',
    label: 'D[1] 2Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_2min_close',
    label: 'D[1] 2Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_2min_close_pct',
    label: 'D[1] 2Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_2min_vol',
    label: 'D[1] 2Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_3min_high',
    label: 'D[1] 3Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_3min_high_pct',
    label: 'D[1] 3Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_3min_low',
    label: 'D[1] 3Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_3min_low_pct',
    label: 'D[1] 3Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_3min_close',
    label: 'D[1] 3Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_3min_close_pct',
    label: 'D[1] 3Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_3min_vol',
    label: 'D[1] 3Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_4min_high',
    label: 'D[1] 4Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_4min_high_pct',
    label: 'D[1] 4Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_4min_low',
    label: 'D[1] 4Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_4min_low_pct',
    label: 'D[1] 4Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_4min_close',
    label: 'D[1] 4Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_4min_close_pct',
    label: 'D[1] 4Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_4min_vol',
    label: 'D[1] 4Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_5min_high',
    label: 'D[1] 5Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_5min_high_pct',
    label: 'D[1] 5Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_5min_low',
    label: 'D[1] 5Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_5min_low_pct',
    label: 'D[1] 5Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_5min_close',
    label: 'D[1] 5Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_5min_close_pct',
    label: 'D[1] 5Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_5min_vol',
    label: 'D[1] 5Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_10min_high',
    label: 'D[1] 10Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_10min_high_pct',
    label: 'D[1] 10Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_10min_low',
    label: 'D[1] 10Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_10min_low_pct',
    label: 'D[1] 10Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_10min_close',
    label: 'D[1] 10Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_10min_close_pct',
    label: 'D[1] 10Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_10min_vol',
    label: 'D[1] 10Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_15min_high',
    label: 'D[1] 15Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_15min_high_pct',
    label: 'D[1] 15Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_15min_low',
    label: 'D[1] 15Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_15min_low_pct',
    label: 'D[1] 15Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_15min_close',
    label: 'D[1] 15Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_15min_close_pct',
    label: 'D[1] 15Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_15min_vol',
    label: 'D[1] 15Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_30min_high',
    label: 'D[1] 30Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_30min_high_pct',
    label: 'D[1] 30Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_30min_low',
    label: 'D[1] 30Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_30min_low_pct',
    label: 'D[1] 30Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_30min_close',
    label: 'D[1] 30Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_30min_close_pct',
    label: 'D[1] 30Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_30min_vol',
    label: 'D[1] 30Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_60min_high',
    label: 'D[1] 60Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_60min_high_pct',
    label: 'D[1] 60Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_60min_low',
    label: 'D[1] 60Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_60min_low_pct',
    label: 'D[1] 60Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_60min_close',
    label: 'D[1] 60Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_60min_close_pct',
    label: 'D[1] 60Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_60min_vol',
    label: 'D[1] 60Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_90min_high',
    label: 'D[1] 90Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_90min_high_pct',
    label: 'D[1] 90Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_90min_low',
    label: 'D[1] 90Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_90min_low_pct',
    label: 'D[1] 90Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_90min_close',
    label: 'D[1] 90Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_90min_close_pct',
    label: 'D[1] 90Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_90min_vol',
    label: 'D[1] 90Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_120min_high',
    label: 'D[1] 120Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_120min_high_pct',
    label: 'D[1] 120Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_120min_low',
    label: 'D[1] 120Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_120min_low_pct',
    label: 'D[1] 120Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_120min_close',
    label: 'D[1] 120Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_120min_close_pct',
    label: 'D[1] 120Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_120min_vol',
    label: 'D[1] 120Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_150min_high',
    label: 'D[1] 150Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_150min_high_pct',
    label: 'D[1] 150Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_150min_low',
    label: 'D[1] 150Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_150min_low_pct',
    label: 'D[1] 150Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_150min_close',
    label: 'D[1] 150Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_150min_close_pct',
    label: 'D[1] 150Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_150min_vol',
    label: 'D[1] 150Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_180min_high',
    label: 'D[1] 180Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_180min_high_pct',
    label: 'D[1] 180Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_180min_low',
    label: 'D[1] 180Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_180min_low_pct',
    label: 'D[1] 180Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_180min_close',
    label: 'D[1] 180Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_180min_close_pct',
    label: 'D[1] 180Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_180min_vol',
    label: 'D[1] 180Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_210min_high',
    label: 'D[1] 210Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_210min_high_pct',
    label: 'D[1] 210Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_210min_low',
    label: 'D[1] 210Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_210min_low_pct',
    label: 'D[1] 210Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_210min_close',
    label: 'D[1] 210Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_210min_close_pct',
    label: 'D[1] 210Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_210min_vol',
    label: 'D[1] 210Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_240min_high',
    label: 'D[1] 240Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_240min_high_pct',
    label: 'D[1] 240Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_240min_low',
    label: 'D[1] 240Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_240min_low_pct',
    label: 'D[1] 240Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_240min_close',
    label: 'D[1] 240Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_240min_close_pct',
    label: 'D[1] 240Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_240min_vol',
    label: 'D[1] 240Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_270min_high',
    label: 'D[1] 270Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_270min_high_pct',
    label: 'D[1] 270Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_270min_low',
    label: 'D[1] 270Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_270min_low_pct',
    label: 'D[1] 270Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_270min_close',
    label: 'D[1] 270Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_270min_close_pct',
    label: 'D[1] 270Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_270min_vol',
    label: 'D[1] 270Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_300min_high',
    label: 'D[1] 300Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_300min_high_pct',
    label: 'D[1] 300Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_300min_low',
    label: 'D[1] 300Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_300min_low_pct',
    label: 'D[1] 300Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_300min_close',
    label: 'D[1] 300Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_300min_close_pct',
    label: 'D[1] 300Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_300min_vol',
    label: 'D[1] 300Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_330min_high',
    label: 'D[1] 330Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_330min_high_pct',
    label: 'D[1] 330Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_330min_low',
    label: 'D[1] 330Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_330min_low_pct',
    label: 'D[1] 330Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_330min_close',
    label: 'D[1] 330Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_330min_close_pct',
    label: 'D[1] 330Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_330min_vol',
    label: 'D[1] 330Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_360min_high',
    label: 'D[1] 360Min High',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_360min_high_pct',
    label: 'D[1] 360Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_360min_low',
    label: 'D[1] 360Min Low',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_360min_low_pct',
    label: 'D[1] 360Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_360min_close',
    label: 'D[1] 360Min Close',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_360min_close_pct',
    label: 'D[1] 360Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_360min_vol',
    label: 'D[1] 360Min Vol',
    group: GROUPS.HOURLY_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day1_1min_high_pct',
    label: 'D[2] 1Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_1min_low_pct',
    label: 'D[2] 1Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_1min_close_pct',
    label: 'D[2] 1Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_2min_high_pct',
    label: 'D[2] 2Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_2min_low_pct',
    label: 'D[2] 2Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_2min_close_pct',
    label: 'D[2] 2Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_3min_high_pct',
    label: 'D[2] 3Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_3min_low_pct',
    label: 'D[2] 3Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_3min_close_pct',
    label: 'D[2] 3Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_4min_high_pct',
    label: 'D[2] 4Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_4min_low_pct',
    label: 'D[2] 4Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_4min_close_pct',
    label: 'D[2] 4Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_5min_high_pct',
    label: 'D[2] 5Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_5min_low_pct',
    label: 'D[2] 5Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_5min_close_pct',
    label: 'D[2] 5Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_15min_high_pct',
    label: 'D[2] 15Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_15min_low_pct',
    label: 'D[2] 15Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_15min_close_pct',
    label: 'D[2] 15Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_30min_high_pct',
    label: 'D[2] 30Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_30min_low_pct',
    label: 'D[2] 30Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_30min_close_pct',
    label: 'D[2] 30Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_60min_high_pct',
    label: 'D[2] 60Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_60min_low_pct',
    label: 'D[2] 60Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_60min_close_pct',
    label: 'D[2] 60Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_90min_high_pct',
    label: 'D[2] 90Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_90min_low_pct',
    label: 'D[2] 90Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_90min_close_pct',
    label: 'D[2] 90Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_120min_high_pct',
    label: 'D[2] 120Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_120min_low_pct',
    label: 'D[2] 120Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_120min_close_pct',
    label: 'D[2] 120Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_150min_high_pct',
    label: 'D[2] 150Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_150min_low_pct',
    label: 'D[2] 150Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_150min_close_pct',
    label: 'D[2] 150Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_180min_high_pct',
    label: 'D[2] 180Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_180min_low_pct',
    label: 'D[2] 180Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_180min_close_pct',
    label: 'D[2] 180Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_210min_high_pct',
    label: 'D[2] 210Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_210min_low_pct',
    label: 'D[2] 210Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_210min_close_pct',
    label: 'D[2] 210Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_240min_high_pct',
    label: 'D[2] 240Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_240min_low_pct',
    label: 'D[2] 240Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_240min_close_pct',
    label: 'D[2] 240Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_270min_high_pct',
    label: 'D[2] 270Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_270min_low_pct',
    label: 'D[2] 270Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_270min_close_pct',
    label: 'D[2] 270Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_300min_high_pct',
    label: 'D[2] 300Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_300min_low_pct',
    label: 'D[2] 300Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_300min_close_pct',
    label: 'D[2] 300Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_330min_high_pct',
    label: 'D[2] 330Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_330min_low_pct',
    label: 'D[2] 330Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_330min_close_pct',
    label: 'D[2] 330Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_360min_high_pct',
    label: 'D[2] 360Min High %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_360min_low_pct',
    label: 'D[2] 360Min Low %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_360min_close_pct',
    label: 'D[2] 360Min Close %',
    group: GROUPS.HOURLY_METRICS.name,
    change: true,
  }),

], GROUPS);



export const GRID_COLUMNS = getColumnDefsFor('grid', ALL_COLUMNS);
export const FILTER_COLUMNS = attachSynonymsToColumns(
  getColumnDefsFor('filter', ALL_COLUMNS),
  BASE_SYNONYMS
);
export const COLUMN_COLUMNS = attachSynonymsToColumns(
  getColumnDefsFor('column', ALL_COLUMNS),
  BASE_SYNONYMS
);



