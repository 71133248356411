import TopListScanner from '../TopListScanner';
import TopListChart from '../TopListChart';
import TopListKeystats from '../TopListKeyStats';
import TopListNews from '../TopListNews';
import TopListHistorical from '../TopListHistorical';
import ComponentSelectPanel from './ComponentSelectPanel';
import PlaceholderComponent from './PlaceholderComponent';
import TopListWatchlist from 'src/app/TopListsMosaic/TopListWatchlist';
import {
  NewsIcon,
  TopListsIcon,
  LayoutIcon,
  HistoryIcon,
  GraphsidewaysIcon,
  OverviewIcon,
  WatchlistIcon
} from 'src/theme/EdgeIcons';
import { EXPR_CTX } from 'src/redux/expressions/globalExpressionReducer';

/**
 * Enum for component type ID's
 * @readonly
 * @enum {string} COMPONENT_TYPES
 */
export const COMPONENT_TYPES = {
  SELECT: 'select',
  SCANNER: 'scanner',
  NEWS: 'news',
  KEYSTATS: 'keystats',
  HISTORICAL: 'historical',
  CHART: 'chart',
  WATCHLIST: 'watchlist',
  DILUTION: 'dilution',
};


/**
 * List of which COMPONENT_TYPES are available for users to select
 *
 * @readonly
 * @type {COMPONENT_TYPES[]}
 */
export const AVAILABLE_COMPONENTS = [
  COMPONENT_TYPES.SCANNER,
  COMPONENT_TYPES.WATCHLIST,
  COMPONENT_TYPES.NEWS,
  COMPONENT_TYPES.KEYSTATS,
  COMPONENT_TYPES.HISTORICAL,
  COMPONENT_TYPES.CHART,
  // COMPONENT_TYPES.DILUTION
];

/**
 * Maps COMPONENT_TYPES to relevant React components and render options
 *
 * @typedef {Object} ComponentMapItem
 * @property {string} title - Title of the component
 * @property {React.Component} Component - React component to render
 * @property {React.Component} [Icon] - Icon to display in the component select panel
 * @property {boolean} [noLinkAllowed] - Hide the linking dropdown menu.
 */

/**
 * @readonly
 * @type {{COMPONENT_TYPES: ComponentMapItem}} COMPONENT_MAP
 */
export const COMPONENT_MAP = {
  [COMPONENT_TYPES.SELECT]: {
    title: 'Select Component',
    Component: ComponentSelectPanel
  },
  [COMPONENT_TYPES.SCANNER]: {
    title: 'Scanner',
    Icon: TopListsIcon,
    expressionContextKey: EXPR_CTX.SCANNER,
    Component: TopListScanner
  },
  [COMPONENT_TYPES.WATCHLIST]: {
    title: 'Watchlist',
    Icon: WatchlistIcon,
    expressionContextKey: EXPR_CTX.SCANNER,
    Component: TopListWatchlist
  },
  [COMPONENT_TYPES.NEWS]: {
    title: 'News',
    Icon: NewsIcon,
    Component: TopListNews,
    expressionContextKey: EXPR_CTX.SCANNER,
    noLinkAllowed: true
  },
  [COMPONENT_TYPES.KEYSTATS]: {
    title: 'Key Stats',
    Icon: OverviewIcon,
    Component: TopListKeystats
  },
  [COMPONENT_TYPES.HISTORICAL]: {
    title: 'Historical Stats',
    Icon: HistoryIcon,
    expressionContextKey: EXPR_CTX.HISTORICAL_COMP,
    Component: TopListHistorical
  },
  [COMPONENT_TYPES.CHART]: {
    title: 'Chart',
    Icon: GraphsidewaysIcon,
    Component: TopListChart
  },
  [COMPONENT_TYPES.DILUTION]: {
    title: 'Dilution',
    Icon: LayoutIcon,
    Component: PlaceholderComponent
  }
};
