import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useFormSelector, useFormDispatch, useFormActions } from 'src/app/slicedForm/shared/context/FormProvider';
import { selectIsAnyFieldInvalid } from 'src/app/slicedForm/FilterForm/reducers/filterReducer';
import ActionList, { useDoubleConfirmationState } from 'src/app/components/ActionList';
import ClearIcon from '@material-ui/icons/Clear';
import LockIcon from '@material-ui/icons/Lock';
import { flashError } from "../styles/useInputStyles";
import { useNotificationApi } from "../context/NotificationProvider";
import { CopyIcon, ConfirmIcon, CheckIcon } from 'src/theme/EdgeIcons';
import {
  SELECT_PROFILE,
  COPY_PROFILE,
  DELETE_PROFILE,
  generateId,
} from 'src/app/slicedForm/shared/reducers/profileReducer';
import {
  Box,
  Typography,
  Button,
  makeStyles,
  useTheme,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1)
  },
}));


function ProfilesSection({ className }) {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useFormDispatch();
  const activeProfileId = useFormSelector(state => state.activeProfile);
  const profiles = useFormSelector(state => state.profiles);
  const {
    confirming,
    isAnyConfirming,
    setConfirming
  } = useDoubleConfirmationState();
  const isAnyInvalid = useFormSelector(selectIsAnyFieldInvalid);
  const { enqueueNotification } = useNotificationApi();


  const guardErrors = (callback) => {
    if (isAnyInvalid) {
      enqueueNotification({
        message: 'Please correct the errors in your form before continuing',
        severity: 'error'
      });
      flashError();
    } else {
      callback();
    }
  }


  return (
    <ActionList
      className={clsx(classes.root, className)}
    >

      {profiles.map(profile => {
        const isConfirming = confirming(profile?.id);

        return (
          <ActionList.Item
            key={profile.id}
            id={profile.id}
            onSelect={() => !isAnyConfirming && guardErrors(() => dispatch({ type: SELECT_PROFILE, payload: profile.id }))}
            selected={profile.id === activeProfileId}
            role="menuitem"
            title={profile.name}
            pl={profile.predefined ? undefined : theme.spacing(2)}
          >
            {profile.predefined && (
              <ActionList.Leading margin={2}>
                <LockIcon className="lockIcon" />
              </ActionList.Leading>
            )}
            {profile.name}
            <ActionList.Trailing>
              {!isConfirming && (
                <ActionList.IconButton
                  Icon={CopyIcon}
                  label={'Copy'}
                  onClick={() => {
                    guardErrors(() => {
                      dispatch({
                        type: COPY_PROFILE,
                        payload: {
                          id: profile.id,
                          name: profile.name,
                          newId: generateId()
                        }
                      });
                    })
                  }}
                />
              )}
              {!profile.predefined ? (
                <ActionList.DoubleConfirmationIconButton
                  confirming={isConfirming}
                  BaseIcon={ClearIcon}
                  baseLabel={'Delete'}
                  baseColor={theme.palette.primary.main}
                  ConfirmIcon={CheckIcon}
                  confirmLabel={'Confirm delete?'}
                  confirmColor={theme.palette.text.negative}
                  setConfirming={setConfirming(profile.id)}
                  onAccept={() => dispatch({ type: DELETE_PROFILE, payload: profile.id })}
                />
              ) : (
                <ActionList.IconButton placeholder />
              )}
            </ActionList.Trailing>
          </ActionList.Item>
        );
      })}
    </ActionList>
  );
}

export default ProfilesSection;
