import React from 'react';
import PropTypes from 'prop-types'
import clsx from 'clsx';
import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {},
  titleCont: {
    width: props => `calc(${props.titleWidth}px - 7px + 2px)`,
  }
}))


function FormHeader({
  className, 
  title,
  titleWidth,
  children,
}) {
  const classes = useStyles({ titleWidth })

  return (
    <Box 
      className={clsx(className, classes.root)}
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      flex={1}
    >
      <div className={classes.titleCont}>
      {React.isValidElement(title) 
        ? title 
        : <Typography variant="h4">{title}</Typography>
      }
      </div>
      {children}
    </Box>
  )
}

FormHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  titleWidth: PropTypes.number,
  children: PropTypes.node,
}

FormHeader.defaultProps = {
  titleWidth: 260
}

export default FormHeader
