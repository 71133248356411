import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Case } from 'src/app/components/utility/SwitchCase';
import { useProfileLocked } from 'src/app/slicedForm/shared/context/ProfileLockedProvider';
import { useFormDispatch, useFormSelector } from 'src/app/slicedForm/shared/context/FormProvider';
import {
  COPY_PROFILE,
  selectActiveProfile,
  generateId
} from 'src/app/slicedForm/shared/reducers/profileReducer';
import ContainedModal from "src/app/components/utility/ContainedModal";
import {
  Typography,
  Button,
  Box,
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  clickBlocker: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
    // backgroundColor: props => props.open ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
  }
}));


/**
 * Click-blocker is positioned absolutely over some content, if the profile is predefined.
 * Once clicked, open a modal with a description and a button to copy the profile.
 *
 * TODO: We can make this uncontrolled, and wrap with Controlled() HOC if we need
 * other implementations
 *
 * @component
 */
function PredefinedProfileEditBlocker({
  className,
  description,
  copy
}) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { locked } = useProfileLocked();
  const { name, id } = useFormSelector(selectActiveProfile) || {};
  const dispatch = useFormDispatch();

  const handleCopy = () => {
    dispatch({
      type: COPY_PROFILE,
      payload: {
        id,
        name,
        newId: generateId()
      }
    })
  }

  if (!locked) {
    return null;
  }

  return (
    <Switch expr={open}>
      <Case when={false}>
        <div
          onClick={(event) => {
            event.stopPropagation();
            setOpen(true);
          }}
          className={classes.clickBlocker}
        />
      </Case>
      <Case when={true}>
        <ContainedModal
          className={className}
          open={true}
          width={450}
          onClose={() => setOpen(false)}
        >
          <Box
            textAlign="center"
            p={2}
          >
            <Typography variant="body1" gutterBottom>
              {description}
            </Typography>
            <Box
              display="flex"
              alignItems="space-between"
              justifyContent="center"
              mt={3}
            >
              {copy && (
                <Box ml={1} mr={1}>
                  <Button
                    onClick={handleCopy}
                    color="primary"
                    variant="contained"
                    size="small"
                  >
                    Copy {name}
                  </Button>
                </Box>
              )}
              <Box ml={1} mr={1}>
                <Button
                  onClick={() => setOpen(false)}
                  color="primary"
                  variant="outlined"
                  size="small"
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Box>
        </ContainedModal>
      </Case>
    </Switch>
  );
}


PredefinedProfileEditBlocker.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  /**
   * Allow user to make a copy via profileReducer
   */
  copy: PropTypes.bool
};


PredefinedProfileEditBlocker.defaultProps = {
  description: 'You cannot edit a predefined profile. Make an editable copy?',
  copy: true,
};


export default PredefinedProfileEditBlocker;
