import React from 'react';
import {
  ADD_COLUMN,
  DELETE_COLUMN,
} from "src/app/slicedForm/ColumnForm/reducers/columnReducer";
import { useFormDispatch } from "src/app/slicedForm/shared/context/FormProvider";
import FormHeader from 'src/app/slicedForm/shared/elements/FormHeader';
import FormModal from 'src/app/components/FormModal';
import { FormActions, FormCloseButton } from "src/app/slicedForm/shared/elements/FormActions";
import ColumnSelectionInner from "src/app/slicedForm/ColumnForm/elements/ColumnSelectionInner";
import SelectedColumns from "src/app/slicedForm/ColumnForm/elements/SelectedColumns";
import PredefinedProfileEditBlocker from 'src/app/slicedForm/shared/elements/PredefinedProfileEditBlocker';
import ProfileNameField from 'src/app/slicedForm/shared/elements/ProfileNameField';
import {
  makeStyles,
} from '@material-ui/core'
import { useColumnDefs } from '../../shared/context/ColumnDefsProvider';


const useStyles = makeStyles(theme => ({
  root: {},
  panelHeaderColor: {
    backgroundColor: theme.palette.background.panelHeader
  },
  relative: {
    position: 'relative',
  }
}))


function Form() {
  const classes = useStyles()
  const dispatch = useFormDispatch();
  const colDefs = useColumnDefs();

  const handleSelect = (colDef, isSelected) => {
    if (!colDef || !Object.keys(colDef).length || !('name' in colDef)) {
      console.warn('SlicedColumn Form.js handleSelect colDef arg invalid!', colDef);
      return;
    }
    const type = isSelected ? DELETE_COLUMN : ADD_COLUMN;
    dispatch({
      type,
      payload: {
        column: {
          column: colDef.name
        },
        colDefs
      }
    })
  }

  return (
    <>
      <FormModal.Actions
        className={classes.panelHeaderColor}
        borderBottom
      >
        <FormHeader
          className={classes.title}
          title="Customize Columns"
          titleWidth={260}
        >
          <ProfileNameField />
        </FormHeader>
        <FormCloseButton />
      </FormModal.Actions>

      <FormModal.Body className={classes.relative}>
        <ColumnSelectionInner
          leftColumnClassName={classes.panelHeaderColor}
          rightColumnClassName={classes.panelHeaderColor}
          showProfileControls
          onSelect={handleSelect}
        />
        <FormModal.Column
          title="Selected Columns"
          minWidth={230}
          maxWidth={230}
        >
          <FormModal.Section
            expand
            scrollable
            className={classes.relative}
          >
            <PredefinedProfileEditBlocker disablePortal={false} />
            <SelectedColumns />
          </FormModal.Section>
        </FormModal.Column>
      </FormModal.Body>

      <FormModal.Actions
        className={classes.panelHeaderColor}
        borderTop
      >
        <FormActions />
      </FormModal.Actions>

    </>
  )
}

export default Form
