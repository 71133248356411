import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  RENAME_PROFILE
} from 'src/app/slicedForm/shared/reducers/profileReducer';
import useStateFromProp from 'src/hooks/useStateFromProp';
import { useFormDispatch, useFormSelector } from "src/app/slicedForm/shared/context/FormProvider";
import { selectActiveProfile } from 'src/app/slicedForm/shared/reducers/profileReducer';
import FilterWindowNameTextField from 'src/app/components/filterElements/FilterWindowNameTextField';
import { useProfileLocked } from 'src/app/slicedForm/shared/context/ProfileLockedProvider';
import { EditIcon } from 'src/theme/EdgeIcons';
import {
  makeStyles,
} from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 300,
    width: '100%'
  },
}))

const emptyObj = {}

function ProfileNameField({ className }) {
  const classes = useStyles();
  const dispatch = useFormDispatch();
  const profile = useFormSelector(selectActiveProfile) || emptyObj;
  const { locked } = useProfileLocked();
  const [newName, setNewName] = useStateFromProp(profile?.name);

  const handleProfileNameChange = (event) => {
    if (profile?.predefined) return;
    setNewName(event.target.value)
  }

  const handleProfileNameBlur = (name) => {
    if (!name) {
      setNewName(profile.name)
      return
    }
    if (name === profile.name) return
    dispatch({
      type: RENAME_PROFILE,
      payload: {
        id: profile.id,
        newName: name
      }
    })
  }

  return (
    <FilterWindowNameTextField
      className={clsx(className, classes.root)}
      Icon={EditIcon}
      value={newName}
      placeholder={'Choose a name...'}
      onChange={handleProfileNameChange}
      onBlur={() => handleProfileNameBlur(newName)}
      disabled={locked}
    />
  )
}

export default ProfileNameField
