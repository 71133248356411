import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { UPDATE_EXPRESSIONS, EXPR_CTX, makeSelectExpression, makeSelectListExpressions, cleanExpression } from './globalExpressionReducer';
import useParameterizedSelector from 'src/hooks/useParameterizedSelector';


const defaultValue = {
  contextKey: null,
}

const GlobalExpressionContext = createContext(defaultValue);





/**
  * A connector intended to wrap around SlicedForms.
  * Specifies which context to use for expressions (realtime, historical),
  * and how to select/dispatch them.
  *
  * Passing a null contextKey will "disable" expressions for the childen.
  **/
function GlobalExpressionProvider({
  contextKey,
  children
}) {
  const value = useMemo(() => {
    return {
      contextKey,
      isExpressionsAvailable: Boolean(contextKey)
    }
  }, [contextKey]);

  return (
    <GlobalExpressionContext.Provider value={value}>
      {children}
    </GlobalExpressionContext.Provider>
  );
}


/**
 * Check if expressions are available for the current context.
 **/
export const useIsExpressionsAvailable = () => {
  const { contextKey } = useContext(GlobalExpressionContext);
  return Boolean(contextKey);
}


/**
 * Select all expressions from Redux matching your current Context key.
 **/
export const useListGlobalExpressions = () => {
  const { contextKey } = useContext(GlobalExpressionContext);
  return useParameterizedSelector(makeSelectListExpressions, contextKey);
}


/**
 * Persist expressions to Redux.
 **/
export const useMakeExpressionAction = () => {
  const { contextKey } = useContext(GlobalExpressionContext);

  return useCallback((expressions) => {
    return { type: UPDATE_EXPRESSIONS, payload: { expressions, contextKey } };
  }, [contextKey])
}


export const useMakeExpressionPayload = () => {
  const { contextKey } = useContext(GlobalExpressionContext);

  return useCallback((expressions) => {
    const cleaned = expressions.map(exp => cleanExpression(exp, contextKey))
    return { expressions: cleaned, contextKey, timestamp: + new Date() };
  }, [contextKey])
}


export default GlobalExpressionProvider;
