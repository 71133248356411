import 'src/app/components/css/prose-mirror.css'
import React, { useCallback, useState, useMemo } from 'react';
import clsx from 'clsx';
import { batch } from 'react-redux';
import { EditorContent, useEditor } from '@tiptap/react';
import { Node } from '@tiptap/core';
import { Text } from '@tiptap/extension-text';
import { Paragraph } from '@tiptap/extension-paragraph';
import { TextStyle } from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import { Bold } from '@tiptap/extension-bold';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Mention } from '@tiptap/extension-mention';
import useColumnSuggestion from '../expressionUtils/useColumnSuggestion';
import { useColumnDefs } from '../../shared/context/ColumnDefsProvider';
import { useFormDispatch, useFormSelector } from '../../shared/context/FormProvider';
import getArithmaticParser from '../arithmatic/ArithmeticParser';
import { profileExpressionToJson, jsonExpressionToProfile } from '../expressionUtils/expressionMappings';
import {
  UPDATE_EXPRESSION_DETAILS_EXPRESSION,
  SET_EXPRESSION_ERROR
} from 'src/app/slicedForm/ExpressionForm/reducers/expressionDetailsReducer'
import {
  Button,
  makeStyles,
} from '@material-ui/core';


const emptyObj = {};


/** Enforeces only a single line of text in the editor **/
const SingleNodeDocument = Node.create({
  name: 'doc',
  topNode: true,
  content: 'block',
});

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 40
  }
}));

/**
 * A wyswig text editor for inputting Math Expressions.
 * tiptap/mention is used to organically show and insert column names
 * when typing.
 **/
function ExpressionInput({
  className,
}) {
  const classes = useStyles();
  const dispatch = useFormDispatch();
  const colDefs = useColumnDefs();
  const arithmaticParser = useMemo(() => getArithmaticParser(), []); // Eventually, this might not be static.
  const suggestionConfig = useColumnSuggestion(colDefs, 'mention');
  const expression = useFormSelector(state => state.currentExpression || emptyObj);
  const error = useFormSelector(state => state?.errors?.currentExpression);


  const handleBlur = useCallback(({ event, editor }) => {
    if (event.relatedTarget?.classList.contains('tiptap-expression-input-skip-blur')) {
      return;
    }
    const payload = jsonExpressionToProfile(editor.getJSON());

    let errMsg = null;
    try {
      arithmaticParser.validate(payload.expression);
    } catch (err) {
      errMsg = err.message;
    }
    batch(() => {
      dispatch({
        type: SET_EXPRESSION_ERROR,
        payload: { currentExpression: errMsg }
      });
      dispatch({
        type: UPDATE_EXPRESSION_DETAILS_EXPRESSION,
        payload: { ...payload }
      })
    })


  }, [colDefs]);


  const handleClearErrors = useCallback(() => {
    if (!error) return;

    dispatch({
      type: SET_EXPRESSION_ERROR,
      payload: { currentExpression: null }
    });
  }, [error]);


  const editor = useEditor({
    extensions: [
      SingleNodeDocument,
      Paragraph,
      Text,
      TextStyle,
      Bold,
      Color,
      Placeholder.configure({
        placeholder: '(@Price + @Open) / (@Price - @Open) * 100'
      }),
      Mention.configure({
        HTMLAttributes: {
          class: suggestionConfig.name
        },
        suggestion: suggestionConfig,
        deleteTriggerWithBackspace: true,
      })
    ],
    editorProps: {
      attributes: {
        class: `--theme-dark tt-expression-input`,
      },
    },
    content: profileExpressionToJson(expression, colDefs) || '', // should be named 'initialContent'
  }, [suggestionConfig]);


  useMemo(() => {
    // If changing profiles, make sure we sync the content.
    // Right now does nothing, since we can't show multiple profiles at once.
    if (!editor) return;

    editor.commands.setContent(profileExpressionToJson(expression, colDefs) || '');
  }, [editor, expression?.name])


  useMemo(() => {
    // When the useEditor has a dependancy change, it remounts.
    // This is slow and causes a flicker.
    //
    // Set the necissary callbacks here without remounting the editor.
    if (!editor) return;

    editor.on('update', handleClearErrors);
    editor.on('blur', handleBlur);

    return () => {
      editor.off('update');
      editor.off('blur')
    }
  }, [editor, handleClearErrors, handleBlur]);


  return (
    <div
      className={clsx(className, classes.root)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          console.log('ExpressionInput ENTER');
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <EditorContent
        editor={editor}
      />
    </div >
  )
}


export default ExpressionInput;
