import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useMemo
} from "react";
import { selectActiveProfile } from "../reducers/profileReducer";
import { useFormSelector } from "./FormProvider";
import { PREDEF_PREFIX } from "src/redux/profileSettings/profileSettingsConfig";




const defaultValue = {
  locked: false
}


const LockedContext = createContext(defaultValue);


/**
 * We must disable all form inputs for Predefined profiles.
 * This isn't as easy as blocking clicks. Tab-index, onEnter, onChange, etc. all must be disabled.
 * 
 * This wraps the entire form, and allows each element to make a decision.
 * Typically, inner elements will pass down 'disabled' through props or separate context,
 * in an attempt to separate Profiles from elements. Kind of unecissary though, coupling is already
 * increadibly tight.
 */
export default function ProfileLockedProvider({ children }) {
  const activeProfile = useFormSelector(selectActiveProfile);
  const locked = !activeProfile || activeProfile?.predefined || activeProfile?.id.startsWith(PREDEF_PREFIX);

  const value = useMemo(() => {
    return { locked }
  }, [locked]);

  return (
    <LockedContext.Provider value={value}>
      {children}
    </LockedContext.Provider>
  )
}


/**
 * Lock predefined profiles from being edited.
 * @returns {{ locked: boolean }}
 */
export const useProfileLocked = () => {
  const context = useContext(LockedContext);
  return context;
}