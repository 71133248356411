import React, { useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import { makeStyles, Typography, Box, useTheme } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HoverInfoPopper from 'src/app/components/elements/HoverInfoPopper';
import mapSingleProfileStructToPreviewStruct from 'src/app/slicedForm/mapping/profileToPreview';
import { MAX_YEARS } from 'src/hooks/useUserPlanPermissions';
import { useListGlobalExpressions } from 'src/redux/expressions/globalExpressionContext';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.darkBlue
  },
  activeFiltersBackgroundTarget: {
    flex: 1,
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    // cursor: 'ew-resize',
    '&:after': {
      content: '\'\'',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      width: 52,
      backgroundColor: 'transparent',
      pointerEvents: 'none'
    },
    '& .history-filter-item-display': {
      padding: '5px 9px',
      // padding: '4px 0px',
      fontSize: theme.fontSize.data,
      '& h5': {
        fontSize: 12,
        lineHeight: 1.43,
        whiteSpace: 'nowrap'
      }
    },
  },
  popper: {
    backgroundColor: theme.palette.background.panelHeader,
    border: `1px solid ${theme.palette.border}`,
  },
  group_AND: {
    borderRadius: 4,
    minWidth: 300,
    '& p': {
      fontSize: 13,
      lineHeight: 1.43,
      whiteSpace: 'nowrap'
    }
  },
  group_OR: {
    borderRadius: 4,
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  filter: {
    padding: 5,
    display: 'flex'
  },
  left: {
    flex: 2,
    '& p': {
      fontWeight: 'bold',
    },
    paddingRight: 10
  },
  right: {
    flex: 1,
    textAlign: 'right',
  },
  activeFiltersOverflow: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    '&:hover': {
      overflow: 'auto'
    }
  },
  activeFilters: {
    display: 'flex',
  },
  dateWarning: {
    maxWidth: 300,
    textAlign: 'center',
    '& p': {
      fontSize: 13,
      lineHeight: 1.2
    }
  }
}));


const getGroup = (node) => {
  if (!node || !(typeof node === 'object')) return null;
  if ('AND' in node) return 'AND';
  if ('OR' in node) return 'OR';
  return null;
}


function PreviewError({ children }) {
  return (
    <Box p={1}>
      <Typography>{children}</Typography>
    </Box>
  )
}


/**
 * Need to convert the nested s:let arrayIndex = array.length
 while (arrayIndex--) {
  
 }tuff into a flat structure of carousel items.
 * 
 * Not sure what to do with SLICES. Probably resolve them unfortunately.
 * Not sure what to do with BETWEEN. Can't resolve them like slices.
 * Not sure what to do with carousel items, they cannot be individual. (A or B)
 * 
 * This is kind of trash.
 * 
 * (Vol > 1)(Vol < 2)(Vol > 1 OR Vol < 2)(Vol > 1),(Vol < 2)
 */
function LayoutActiveFilterBar({
  className,
  filterProfile,
  columnDefs,
  expressions,
  maxYears,
  children
}) {
  const classes = useStyles();
  const theme = useTheme();

  const previewProfile = useMemo(() => {
    return mapSingleProfileStructToPreviewStruct({ filters: filterProfile.filters, columnDefs, maxYears, expressions })
  }, [filterProfile.filters, columnDefs, maxYears, expressions]);


  const tryRenderPreview = (previewProfile) => {
    if (!previewProfile || !previewProfile?.['AND'] || !previewProfile?.['AND'].length) {
      return <PreviewError>No filters selected</PreviewError>
    }
    try {
      return renderPreview(previewProfile);
    } catch (err) {
      Sentry.captureException(err);
      return <PreviewError>Error loading preview...</PreviewError>
    }
  }

  /**
   * Takes a profile mapped using profileToPreview and returns HTML
   **/
  const renderPreview = useCallback((node, index = 0) => {
    const group = getGroup(node);
    if (group) {
      return (
        <div className={classes[`group_${group}`]} key={index}>
          {node[group].map((n, i) => renderPreview(n, i))}
        </div>
      )
    }
    return (
      <div className={classes.filter} key={index}>
        <div className={classes.left}>
          <Typography style={node.left?.style}>{node.left.text}</Typography>
          {node.left?.icon && <node.left.icon />}
        </div>
        <div className={classes.right}>
          <Typography style={node.right?.style}>{node.right.text}</Typography>
        </div>
      </div>
    )
  }, [classes]);


  const renderDateWarning = useCallback((maxYears) => {
    const yearName = maxYears === 1 ? 'year' : 'years';
    return (
      <div className={classes.dateWarning}>
        <Typography>Your current plan only includes {maxYears} {yearName} of historical records.</Typography>
        <Typography>However, the Stats panel will show data for the entire selected time range.</Typography>
      </div>
    )
  }, [])

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.activeFiltersBackgroundTarget}>
        <div className="history-filter-item-display">
          <HoverInfoPopper
            className={classes.popper}
            arrow={false}
            content={tryRenderPreview(previewProfile)}
          >
            <Typography variant="h5" color="textPrimary" style={{ cursor: 'pointer' }}>
              {filterProfile?.name || 'Unnamed Profile'}
              <HelpOutlineIcon style={{ fontSize: 13, verticalAlign: 'text-top', marginLeft: 4 }} />
            </Typography>
          </HoverInfoPopper>
        </div>
        {maxYears < MAX_YEARS && (
          <div className="history-filter-item-display">
            <HoverInfoPopper
              className={classes.popper}
              arrow={false}
              content={renderDateWarning(maxYears)}
            >
              <Typography variant="h5" style={{ cursor: 'pointer', color: theme.palette.warning.main }}>
                {'Max 1 Year'}
                <HelpOutlineIcon style={{ color: theme.palette.warning.main, fontSize: 13, verticalAlign: 'text-top', marginLeft: 4 }} />
              </Typography>
            </HoverInfoPopper>
          </div>
        )}
      </div>
      {children}
    </div>
  );
}




LayoutActiveFilterBar.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.object,
  columnOrder: PropTypes.arrayOf(PropTypes.string),
  columnDefs: PropTypes.arrayOf(PropTypes.object),
  maxYears: PropTypes.number,
  children: PropTypes.any,
};


export default LayoutActiveFilterBar;

