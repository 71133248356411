
import React from 'react';
import clsx from 'clsx';
import {
  useFormSelector,
  useFormDispatch
} from 'src/app/slicedForm/shared/context/FormProvider';
import { useProfileLocked } from 'src/app/slicedForm/shared/context/ProfileLockedProvider';
import { selectActiveProfile } from 'src/app/slicedForm/shared/reducers/profileReducer';
import Switcher from './AggregateGrouping';
import {
  Box,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1.5),
    paddingLeft: 0, // Drag and drop
    paddingRight: 0
  },
  andGroupButton: {
    '&:focus-visible': {
      ...theme.focus.outline
    },
    borderBottomRightRadius: 4,
    marginLeft: 22.28, // Drag and drop
    '& .MuiButton-label': {
      paddingTop: 2,
      paddingLeft: 6,
      paddingRight: 6
    }
  },
  andGroup: {
    border: `1px solid ${theme.palette.background.lightBorder}`,
    backgroundColor: theme.palette.background.paperAlt_darker1,
    borderRadius: 4,
    borderBottomRightRadius: 0,
    position: 'relative',
  },
}));




/** 
 * Mount-point for Aggregates
 **/
function AggregatesSection({ className }) {
  const classes = useStyles();
  const profile = useFormSelector(selectActiveProfile);
  const { locked } = useProfileLocked();

  if (!profile?.root) {
    return <Typography>No active profile...</Typography>;
  }

  return (
    <div className={clsx(className, classes.root)}>
      <Switcher {...profile.root} />
    </div>
  );
}

export default AggregatesSection;
