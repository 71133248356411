import React, { useMemo, useContext, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import _find from 'lodash/find';
import LayoutContext from '../layout/LayoutContext';
import { useSelector, useDispatch } from 'react-redux';
import useToplistLinkedValues from 'src/hooks/useToplistLinkedValues';
import useFetchKeystats from 'src/app/TopListsMosaic/TopListKeyStats/useFetchKeystats';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import IntercomArticleButton, { INTERCOM_SLUGS, INTERCOM_ARTICLES } from 'src/app/components/intercom/IntercomArticleButton';
import { selectComponent, selectProfileList } from 'src/redux/layout/topListLayoutSelectors';
import { updateComponent, updateKeystatsProfiles } from 'src/redux/layout/topListLayoutActions';
import { TOP_LIST_KEYSTATS_COLUMNS } from 'src/app/TopListsMosaic/TopListKeyStats/topListKeyStatsTableDefinitions';
import { KEYSTATS_EXTRA_CHECKBOXES } from 'src/redux/layout/topListLayoutProfileBases';
import { useSyncronizedTimer } from 'src/context/SyncronizedTimerContext';
import edgeDataApi from 'src/apis/edgeDataApi';
import { ColumnsIcon } from 'src/theme/EdgeIcons';
import { PROFILE_CONFIG } from 'src/redux/layout/topListLayoutSchema';
import TickerTitle from 'src/app/components/ticker/TickerTitle';
import TickerQuote from 'src/app/components/ticker/TickerQuote';
import KeyStatsProfile from 'src/app/components/panels/KeyStats/KeyStatsProfile';
import MosaicPanel from 'src/app/TopListsMosaic/layout/MosaicPanel';
import MosaicPanelHeader from 'src/app/TopListsMosaic/layout/MosaicPanelHeader/MosaicPanelHeader';
import MosaicPanelBody from 'src/app/TopListsMosaic/layout/MosaicPanelBody';
import FilterToggleButtons from 'src/app/components/filterElements/FilterToggleButtons';
import KeyStatsStatTable from 'src/app/components/panels/KeyStats/KeyStatsStatTable';
import {
  makeStyles,
  Typography,
} from '@material-ui/core';


const COL_BREAKPOINT = 220;


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  ratiosTitleSuppliment: {
    display: 'flex',
    '& .ratios-suppliment-pre': {
      width: '100%',
      paddingBottom: 4,
      marginLeft: 5,
      fontSize: 13
    }
  },
  panelStats: {
    flexBasis: '30%',
    flex: 1,
    '& > div': {
      paddingTop: 9
    },
    '& .keysts-0': {
      paddingTop: 5,
      paddingLeft: 8,
      paddingRight: 10
    },
    '& .keysts-1': {
      paddingTop: 3,
      paddingLeft: 8,
      paddingRight: 10
    }
  },
  toggleButtonGroup: {
    flex: 1
  },
  tickerTitle: {
    padding: '9px 10px 0px 10px',
  },
  tickerQuote: {
    padding: '2px 10px 2px 10px',
    // paddingRight: 0
  },
  periodToggleButtons: {
    '& .MuiButtonBase-root': {
      border: 'none !important',
      borderRadius: '0 !important',
      padding: '0 !important',
      textTransform: 'none !important',
      backgroundColor: 'transparent !important',
      '&.Mui-selected': {
        backgroundColor: 'transparent !important',
        '& .MuiToggleButton-label': {
          color: theme.palette.primary.main
        }
      },
    },
    '& .MuiToggleButton-label': {
      color: theme.palette.text.label,
      fontSize: 13,
      fontWeight: 500,
      padding: '5px 8px 6px 0px',
    },
  },
  scroll: {
    overflowX: 'auto',
    overflowY: 'auto',
    flex: 1,
  },
  grid: {
    // padding: 8
    padding: 4
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  column: {
    minWidth: COL_BREAKPOINT,
    flex: 1,
    padding: '0px 4px',
  },
  titleBar: {
    paddingTop: 16,
    paddingBottom: 8,
    paddingLeft: 6,
    paddingRight: 4,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& h4': {
      fontSize: 17
    }
  },
  isSingleColumnLayout: {
    '& .two-col-div-table-row:last-child': {
      borderBottom: '1px solid rgba(81, 81, 81, 1) !important'
    }
  },
  description: {
    margin: '26px 6px 15px 12px',
  },
  float: {
    float: 'left',
    '& a': {
      display: 'inline-block',
      marginRight: 15,
      marginBottom: 5,
      marginTop: 3,
      '& img': {
        maxWidth: 100,
        height: 'auto',
      }
    }
  }
}));


function TopListKeyStats({ className }) {
  const { componentId, layoutId } = useContext(LayoutContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [contRef, isBroken] = useDimensionalBreakpoint({ width: (COL_BREAKPOINT + 5) * 2 });
  const contRef = useRef(400);
  const isBroken = false;
  const {
    financialsPeriod,
    [PROFILE_CONFIG.KEY_STATS.idKey]: keyStatsProfileId,
  } = useSelector(selectComponent(componentId, layoutId));
  const { linkedData, dispatchUpdateLinkedData } = useToplistLinkedValues();
  const { ticker = 'AAPL' } = linkedData;
  const profileList = useSelector(selectProfileList(PROFILE_CONFIG.KEY_STATS.listKey));
  const activeProfile = profileList.find(p => p.id === keyStatsProfileId);
  const [keystats, isFetchingKeystats] = useFetchKeystats(ticker);
  const [isFetchingQuote, setIsFetchingQuote] = useState(true);
  const isFetching = isFetchingQuote || isFetchingKeystats;
  const [quoteData, setQuoteData] = useState({ regular: {}, extended: {} });
  const [hasImageUrl, setHasImageUrl] = useState(false);
  const isMounted = useIsMountedRef();

  const { data, financials } = keystats;
  // const primarySheet = (financials && financials?.primary_sheet) ? financials.primary_sheet : 'quarterly';
  const financialsSheet = useMemo(() => financials ? financials[financialsPeriod] : {}, [financials, financialsPeriod]);
  const keystatsData = useMemo(() => ({ ...data, ...financialsSheet }), [data, financialsSheet]);


  const fetchQuote = async (symbol, shouldSetIsFetching, mounted) => {
    let quote = { regular: {}, extended: {} };

    if (shouldSetIsFetching && mounted.current) {
      setIsFetchingQuote(true);
    }

    try {
      if (ticker) {
        const { data } = await edgeDataApi.get(`/quote/${symbol}`);
        if (data?.regular) {
          quote.regular = data.regular;
        }
        if (data?.extended) {
          quote.extended = data.extended;
        }
      }
    } catch (err) {
      console.log('fetchKeystatsQuoteDataV2', err);
    }

    if (mounted.current) {
      setQuoteData(quote);

      if (shouldSetIsFetching) {
        setIsFetchingQuote(false);
      }
    }
  };


  useEffect(() => {
    data?.logourl ? setHasImageUrl(true) : setHasImageUrl(false);
  }, [data?.logourl]);


  useEffect(() => {
    const mounted = { current: true };
    void fetchQuote(ticker, true, mounted);

    return () => mounted.current = false;
  }, [ticker]);


  useSyncronizedTimer(() => {
    void fetchQuote(ticker, false, isMounted);
  }, 2);


  // Change the structure to fit in the old KeyStatsProfile.js component.
  const initialColumnProfile = useMemo(() => {
    return { profiles: PROFILE_CONFIG.KEY_STATS.predefinedProfiles };
  }, []);


  const profileSettings = useMemo(() => {
    return {
      activeProfile: keyStatsProfileId,
      profiles: profileList
    };
  }, [profileList, keyStatsProfileId]);


  const handleProfileSubmit = (profile) => {
    dispatch(updateKeystatsProfiles(profile, layoutId, componentId));
  };


  const handlePeriodChange = (event, value) => {
    if (value) {
      dispatch(updateComponent(componentId, layoutId, {
        financialsPeriod: value
      }));
    }
  };


  const mapTableRows = (tableRows, sectionId) => {
    const flatColumns = TOP_LIST_KEYSTATS_COLUMNS[sectionId];
    return tableRows.map(name => {
      const rowDefinition = _find(flatColumns, { name });
      const alarms = activeProfile?.alarms?.[name] || {};
      const value = (keystatsData && keystatsData?.[name]) ? keystatsData[name] : null;
      return { value, ...rowDefinition, ...alarms };
    });
  };


  return (
    <MosaicPanel className={clsx(className, classes.root)}>
      <MosaicPanelHeader
        loading={isFetching}
        tickerSearchValue={ticker}
        onTickerSearchSubmit={(symbol) => dispatchUpdateLinkedData({ ticker: symbol })}
      >
        <IntercomArticleButton
          // articleId={INTERCOM_ARTICLES?.toplist?.components?.[COMPONENT_TYPES.KEYSTATS]}
          articleSlug={INTERCOM_SLUGS?.toplist?.components?.keystats}
        />
        <KeyStatsProfile
          Icon={ColumnsIcon}
          shouldHideIconText
          iconText={'Metrics'}
          profileSettings={profileSettings}
          onSubmit={handleProfileSubmit}
          initialColumnProfile={initialColumnProfile}
          COLUMN_DEFINITIONS={TOP_LIST_KEYSTATS_COLUMNS}
          extraCheckboxes={KEYSTATS_EXTRA_CHECKBOXES}
          displayAllMetricsGroup
          maxPerTable={[99, 99]}
          width={900}
          height={960} />
      </MosaicPanelHeader>
      <MosaicPanelBody
        loading={isFetching}
        className={clsx(classes.scroll, 'ett-mosaic-scrollbar')}
      >
        <div>
          <TickerTitle
            className={classes.tickerTitle}
            isFetching={isFetching}
            tickerId={ticker}
            statsData={data}
            hideSymbol
          />
          {activeProfile?.extra?.['show_stock_price'] && (
            <TickerQuote
              className={classes.tickerQuote}
              quoteData={quoteData}
              isFetching={isFetching}
              carousel
            />
          )}
        </div>
        {(!isFetching) && (
          <div className={classes.grid} ref={contRef}>
            {activeProfile.sections.map(({ sectionName, tables }, i) => {
              return (
                <div key={i}>
                  {(sectionName === 'Financials') && (
                    <div className={clsx(classes.titleBar, `keysts-${i}-titlebar`)}>
                      <div style={{ flex: 1 }}>
                        <Typography variant="h4" color="textPrimary">{sectionName}</Typography>
                        <Typography color="textSecondary" variant="body2" className="ratios-suppliment-pre">{keystatsData.dateDisplay}</Typography>
                      </div>
                      <div className={classes.ratiosTitleSuppliment} key={0}>
                        <FilterToggleButtons
                          value={financialsPeriod}
                          exclusive
                          onChange={handlePeriodChange}
                          ariaLabel={'Change reporting period'}
                          className={classes.periodToggleButtons}
                          disableRipple
                          config={[
                            { value: 'quarterly', label: 'Quarterly' },
                            { value: 'annual', label: 'Annual' }
                          ]}
                        />
                      </div>
                    </div>
                  )}
                  <div className={classes.row}>
                    {tables.map((tableRows, j) => {
                      return (
                        <div className={clsx(classes.column, isBroken && classes.isSingleColumnLayout)} key={j}>
                          <KeyStatsStatTable dense={true} rows={mapTableRows(tableRows, sectionName, j)} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            {Boolean(data?.description) && (
              <div className={classes.description}>
                <div className={classes.float}>
                  {hasImageUrl && (
                    <a target="_blank" rel="noreferrer" href={data?.web_url} className="image">
                      <img alt="" src={`https://eodhistoricaldata.com${data['logourl']}`} onError={() => setHasImageUrl(false)} />
                    </a>
                  )}
                </div>
                <Typography variant="body2" color="textPrimary">
                  {data.description}
                </Typography>
              </div>
            )}
          </div>
        )}
      </MosaicPanelBody>
    </MosaicPanel>
  );
}

export default TopListKeyStats;

