import React from "react";
import clsx from 'clsx';
import {
  useFormActions,
  useFormDispatch,
  useFormSelector,
} from 'src/app/slicedForm/shared/context/FormProvider';
import { flashError } from "../styles/useInputStyles";
import PanelActionButton from 'src/app/components/panels/PanelActionButton'
import ClearIcon from '@material-ui/icons/Clear';
import { useProfileLocked } from 'src/app/slicedForm/shared/context/ProfileLockedProvider';
import { selectIsAnyFieldInvalid } from "src/app/slicedForm/FilterForm/reducers/filterReducer";
import { useNotificationApi } from "../context/NotificationProvider";
import {
  IconButton,
  Box,
  Button,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  box: {
    '& .MuiButtonBase-root': {
      margin: [[0, 7]]
    }
  },
  opacity: {
    opacity: 0.5
  },
  closeButton: {
    padding: 0,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      fontSize: 28
    }
  },
}));


/**
 * Bottom bar for FormModals. Hooks up to the actions defined in FormProvider (context)
 * @param {Object} props
 * @param {function} props.selectIsAnyInvalid - FormProvider selector to check if Form buttons should be disabled
 */
export function FormActions({
  selectIsAnyInvalid = selectIsAnyFieldInvalid
}) {
  const classes = useStyles();
  const dispatch = useFormDispatch();
  const { locked } = useProfileLocked();
  const state = useFormSelector(state => state);
  const isAnyInvalid = useFormSelector(selectIsAnyInvalid);
  const { enqueueNotification } = useNotificationApi();
  const {
    onSubmit,
    onSave,
    onCancel,
    onCreate,
    onCopy
  } = useFormActions();

  const guardErrors = (callback) => {
    if (isAnyInvalid) {
      enqueueNotification({
        message: 'Please correct the errors before saving',
        severity: 'error'
      });
      flashError();
    } else {
      callback();
    }
  }

  return (
    <>
      <Box display="flex" alignItems="center" className={classes.box}>
        {onCreate && (
          <PanelActionButton
            color="white"
            className={clsx(isAnyInvalid && classes.opacity)}
            onClick={() => guardErrors(() => onCreate({ state, dispatch }))}
          >
            Create New
          </PanelActionButton>
        )}
        {onCopy && (
          <PanelActionButton
            color="white"
            className={clsx(isAnyInvalid && classes.opacity)}
            onClick={() => guardErrors(() => onCopy({ state, dispatch }))}
          >
            Copy
          </PanelActionButton>
        )}
        {onSave && (
          <PanelActionButton
            color="white"
            className={clsx(isAnyInvalid && classes.opacity)}
            onClick={() => guardErrors(() => onSave({ state, dispatch }))}
          >
            Save
          </PanelActionButton>
        )}
      </Box>
      <Box display="flex" alignItems="center" className={classes.box}>
        {onCancel && (
          <PanelActionButton
            color="red"
            onClick={() => onCancel({})}
          >
            Cancel
          </PanelActionButton>
        )}
        {onSubmit && (
          <PanelActionButton
            color="primary"
            className={clsx(isAnyInvalid && classes.opacity)}
            onClick={() => guardErrors(() => onSubmit({ state, dispatch }))}
          >
            Apply
          </PanelActionButton>
        )}
      </Box>
    </>
  )
}


export function FormCloseButton() {
  const classes = useStyles();
  const { onCancel } = useFormActions();

  return (
    <IconButton
      className={classes.closeButton}
      onClick={onCancel}
      color="primary"
      disableRipple
      aria-label="Close the modal without saving"
    >
      <ClearIcon color="primary" />
    </IconButton>
  )
}

export default FormActions;
