import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import useStateFromProp from 'src/hooks/useStateFromProp';
import FilterWindowNameTextField from 'src/app/components/filterElements/FilterWindowNameTextField';
import { useProfileLocked } from 'src/app/slicedForm/shared/context/ProfileLockedProvider';
import { EditIcon } from 'src/theme/EdgeIcons';
import {
  makeStyles,
} from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 300,
    width: '100%'
  },
}))


function ExpressionLabelInput({
  className,
  label,
  onBlur,
}) {
  const classes = useStyles();
  const { locked } = useProfileLocked();
  const [inputLabel, setInputLabel] = useStateFromProp(label);

  const handleProfileNameChange = (event) => {
    setInputLabel(event.target.value)
  }

  const handleProfileNameBlur = (blurLabel) => {
    if (!blurLabel) {
      setInputLabel(label)
      return
    }
    if (blurLabel === label) return
    onBlur(blurLabel)
  }

  return (
    <FilterWindowNameTextField
      className={clsx(className, classes.root)}
      Icon={EditIcon}
      value={inputLabel}
      placeholder={'Choose a name...'}
      onChange={handleProfileNameChange}
      onBlur={() => handleProfileNameBlur(inputLabel)}
      disabled={locked}
    />
  )
}

export default ExpressionLabelInput
