import StreamAdapter from './StreamAdapter';
import BarBufferInterface from './BarBufferInterface';
import tradingHolidays from 'src/constants/tradingHolidays';
import edgeProxyApi from 'src/apis/edgeProxyApi';

/**
 * @typedef {Object} SymbolInfo
 * @description TradingView's metadata about tickers. Only "name" is important to us
 * @property {string} name - The ticker symbol
 */

/**
 * @typedef {Object} PeriodParams
 * @description TradingView's supplied period parameters. Tells the backend what timeframe to fetch.
 * @property {UnixSeconds} from
 * @property {UnixSeconds} to
 * @property {integer} countBack - Number of bars to fetch (Superscedes 'from' param)
 * @property {boolean} firstDataRequest - True if this is the first request for this symbol/resolution/tv_chart
 */


const configurationData = {
  supported_resolutions: ['1', '2', '3', '5', '10', '15', '30', '60', '1D', '1W', '1M'],
  exchanges: [],
  symbols_types: [],
  currency_codes: ['USD', 'EUR', 'GBP'],
  supports_time: true,
  supports_marks: false,
  supports_timescale_marks: false
};


export default class DataFeed {
  /**
   * TradingView DataFeed API. Must implement these methods.
   *
   * @constructor
   * @param {string} componentId
   * @param {UnixSeconds} [initialDate=null] - Sets the symbol.expired property to force the chart behavior.
   * @param {{string: string}} resolutionConfig
   * @param {boolean} [isRealtime=false]
   *
   * @todo make initialDate plain seconds
   */
  constructor(componentId, initialDate = null, resolutionConfig, isRealtime = false) {
    this.componentId = componentId;
    this.initialDate = initialDate;
    this.isRealtime = isRealtime;
    this.resolutionConfig = resolutionConfig;
  }


  onReady(callback) {
    setTimeout(() => callback(configurationData), 0);
  }


  /** Called when TradingView wants to reset itself. We need to clear our internal buffer */
  resetCache() {
    BarBufferInterface.resetCursor(this.componentId);
  }


  async searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
    let out = [];
    try {
      const response = await edgeProxyApi.get(`/searchticker/${userInput}`);
      if (response.data) {
        out = response.data.map(t => {
          return {
            symbol: t.Symbol,
            full_name: t.Symbol,
            description: t.name,
            ticker: t.Symbol,
            original_currency_code: t.currency,
          };
        });
      }
    } catch (err) { console.log('[searchSymbols]: ERROR! ', err); }
    onResultReadyCallback(out);
  }


  // resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) {
  resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) {
    const { session } = extension;

    const subsessions = [
      {
        description: 'Regular Trading Hours',
        id: 'regular',
        session: '0930-1600'
      },
      {
        description: 'Extended Trading Hours',
        id: 'extended',
        session: '0400-2000'
      },
      {
        description: 'Premarket',
        id: 'premarket',
        session: '0400-0930'
      },
      {
        description: 'Postmarket',
        id: 'postmarket',
        session: '1600-2000'
      }
    ];

    const sessionObj = session ? subsessions.find(s => s.id === session) : subsessions[1];
    // const sessionObj = subsessions[0];

    const symbol = {
      ticker: symbolName,
      name: symbolName,
      description: symbolName,
      supported_resolutions: ['1', '2', '3', '5', '10', '15', '30', '60', '1D', '1W', '1M'],
      timezone: 'America/New_York',
      session_holidays: tradingHolidays.join(','),
      has_intraday: true,
      has_daily: true,
      has_weekly_and_monthly: true,
      intraday_multipliers: ['1', '2', '3', '5', '10', '15', '30', '45', '60'],
      pricescale: 100,
      minmov: 1,
      data_status: 'pulsed',
      has_empty_bars: false,
      session: sessionObj.session,
      subsession_id: sessionObj.id,
      subsessions
    };

    if (this.initialDate) {
      symbol.expired = true;
      symbol.expiration_date = this.initialDate;
    }

    setTimeout(() => onSymbolResolvedCallback(symbol), 0);
  }

  /**
   * TradingView calls this automatically when it wants bars
   * @param {SymbolInfo} symbolInfo
   * @param {string} resolution
   * @param {PeriodParams} periodParams
   * @param {callback} onHistoryCallback
   * @return {Promise<*>}
   */
  async getBars(symbolInfo, resolution, periodParams, onHistoryCallback) {
    const bars = await BarBufferInterface.getBars(this.componentId, symbolInfo.name, resolution, this.initialDate, periodParams);

    if (bars === false || !bars.length) {
      return onHistoryCallback([], { noData: true });
    }

    if (periodParams.firstDataRequest) {
      const hashKey = `${symbolInfo.name}~${resolution}`;
      StreamAdapter.setLastChartedBar(hashKey, { ...bars[bars.length - 1] });
    }

    onHistoryCallback(bars);
  }


  /**
   * Stream ID for connection, to register with Ably
   * @param subscriberUID
   * @return {string}
   */
  handlerId(subscriberUID) {
    return `${this.componentId}_${subscriberUID}`;
  }


  /**
   * TradingView calls this automatically when it wants to connect to Streaming
   * @param {SymbolInfo} symbolInfo
   * @param {string} resolution
   * @param {callback} onRealtimeCallback
   * @param {string} subscriberUID - Internal TV-generated ID of the connection
   * @param {callback} onResetCacheNeededCallback - Unused - not sure
   * @return {undefined}
   */
  subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
    console.log(`[DATAFEED] subscribeBars() ${this.handlerId(subscriberUID)}, ${+new Date()}`);
    if (this.isRealtime && !this.initialDate) {
      StreamAdapter.subscribeToStream(symbolInfo, resolution, onRealtimeCallback, this.handlerId(subscriberUID), onResetCacheNeededCallback);
    }
  }


  /**
   * TradingView calls this automatically when it wants to disconnect from a stream
   * @param {string} subscriberUID - Internal TV-generated ID of the connection
   * @return {undefined}
   */
  unsubscribeBars(subscriberUID) {
    console.log(`[DATAFEED] unsubscribeBars() ${this.handlerId(subscriberUID)}, ${+new Date()}`);
    if (this.isRealtime && !this.initialDate) {
      StreamAdapter.unsubscribeToStream(this.handlerId(subscriberUID));
    }
  }
}

