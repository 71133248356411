import { AgGridReact } from '@ag-grid-community/react';
import clsx from 'clsx';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  buildGridColumns,
} from 'src/app/components/grid/buildColumns';
import {
  formatMarketTime,
  getMostRecentTradingDay,
  getOldestAllowedTopListDate,
} from 'src/utils/datetime/date-fns.tz'
import useDefaultContextMenu from 'src/app/components/grid/contextMenu/useDefaultContextMenu';
import { FILTER_COLUMNS, GRID_COLUMNS } from 'src/app/components/grid/topListHistorical/columns/columnDefs';
import TopListHistoricalDataSource, { FETCHING_STATUS } from 'src/app/components/grid/topListHistorical/datasource/dataSource';
import IntercomArticleButton, { INTERCOM_ARTICLES, INTERCOM_SLUGS } from 'src/app/components/intercom/IntercomArticleButton';
import useToplistLinkedValues from 'src/hooks/useToplistLinkedValues';
import useUserPlanPermissions from 'src/hooks/useUserPlanPermissions';
import useHistoricalMappedExpressions from 'src/app/components/grid/topListHistorical/columns/useHistoricalMappedExpressions';
import useFilterRelevantExpressions from 'src/app/slicedForm/shared/hooks/useFilterRelevantExpressions';
import {
  updateComponent,
  updateHistoryAggregateProfiles,
  updateHistoryColumnProfiles,
  updateHistoryFilterProfiles
} from 'src/redux/layout/topListLayoutActions';
import { PROFILE_CONFIG } from 'src/redux/layout/topListLayoutSchema';
import { selectComponent, selectProfileList } from 'src/redux/layout/topListLayoutSelectors';
import { applyResize, handleKeyboardRowNavigation, onRowSelected } from 'src/utils/agGridFunctions';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import LayoutContext from '../layout/LayoutContext';
import SlicedColumnsForm from './forms/HistoricalSlicedColumnsForm';
import SlicedFiltersForm from './forms/HistoricalSlicedFiltersForm';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import LoadingCellSkeleton from 'src/app/components/grid/cellRenderers/LoadingCellSkeleton';
import PanelIconButton from 'src/app/components/panels/PanelIconButton';
import MosaicPanel from '../layout/MosaicPanel';
import MosaicPanelBody from '../layout/MosaicPanelBody';
import CompactNumberCell from 'src/app/components/grid/cellRenderers/CompactNumberCell';
import MosaicPanelHeader from '../layout/MosaicPanelHeader/MosaicPanelHeader';
import LayoutActiveFilterBar from './LayoutActiveFilterBar/LayoutActiveFilterBar';
import TabPanel from './TabPanel';
import TopListGapStats from './TopListGapStats';
import { makeStyles } from '@material-ui/core';
import { unstable_batchedUpdates } from 'react-dom';
import { useDeepCompareEffect } from 'src/hooks/useDeepCompare';
import HistoricalSlicedAggregatesForm from './forms/HistoricalSlicedAggregatesForm';
import { aggregateToDefinitions } from 'src/app/slicedForm/mapping/aggregateProfileMapping';
import HistoricalAggregatesTable from './HistoricalAggregatesTable';


const useStyles = makeStyles((theme) => ({
  root: {},
  scroll: {
    overflowX: 'auto',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  gridContainer: {
    flex: 1,
    '& .ag-root-wrapper': {
      borderRadius: '0 !important'
    }
  },
  profileWindow: {
    '& .MuiButtonBase-root.MuiIconButton-root': {
      height: '100%',
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 0,
      borderLeft: `2px solid ${theme.palette.background.panelHeader}`
    }
  },
  activeFilterBar: {
    borderBottom: '1px solid rgba(81, 81, 81, 1)'
  },
  tabButton: {
    minWidth: 98,
    height: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 0,
    borderLeft: `2px solid ${theme.palette.background.panelHeader}`,
    '& .MuiSvgIcon-root': {
      margin: 0,
      fontSize: 14
    },
  }
}));



const TABS = [
  { name: 'stats', label: 'Records', years_permissions: 'gap_stats_max_years', icon: ArrowForwardIcon },
  { name: 'records', label: 'Stats', years_permissions: 'history_max_years', icon: ArrowBackIcon },
];


/*
Contains GapStats and History
The Stats/Records return from the same endpoint, even though both don't need to be fetched every time.
For example, a scroll event might trigger Records to be fetched, but stats doesn't need to be updated.

We pass all data into the AGGrid context, and the Grid's datasource will handle fetching. It will set
the results via useState (setStasts, setRecords)
*/

function TopListHistorical({ className }) {
  const { componentId, layoutId } = useContext(LayoutContext);
  const classes = useStyles();
  const [expressions, expressionPayload] = useHistoricalMappedExpressions()
  const dispatch = useDispatch();
  const {
    currentTab,
    gridColumnSizeKey,
    recordsOrder = 'desc',
    recordsOrderby = 'day0_date',
    [PROFILE_CONFIG.HISTORY_COLUMNS.idKey]: columnProfileId,
    [PROFILE_CONFIG.HISTORY_FILTERS.idKey]: filterProfileId,
    [PROFILE_CONFIG.HISTORY_AGGREGATES.idKey]: aggregateProfileId = PROFILE_CONFIG.HISTORY_AGGREGATES.defaultProfileId,
  } = useSelector(selectComponent(componentId, layoutId));


  const { linkedData, dispatchUpdateLinkedData } = useToplistLinkedValues();
  const { ticker = 'AAPL' } = linkedData;

  const permissions = useUserPlanPermissions(['scanner_copy_paste', 'gap_stats_max_years', 'history_max_years']);
  const columnProfilesList = useSelector(selectProfileList(PROFILE_CONFIG.HISTORY_COLUMNS.listKey));
  const filterProfilesList = useSelector(selectProfileList(PROFILE_CONFIG.HISTORY_FILTERS.listKey));
  const aggregateProfileList = useSelector(selectProfileList(PROFILE_CONFIG.HISTORY_AGGREGATES.listKey));
  const columnProfile = columnProfilesList.find(p => p.id === columnProfileId);
  const filterProfile = filterProfilesList.find(p => p.id === filterProfileId);
  const aggregateProfile = aggregateProfileList.find(p => p.id === aggregateProfileId);

  const mostRecentTradingDayString = formatMarketTime(getMostRecentTradingDay(), 'yyyy-MM-dd');

  const relevantExpressions = useFilterRelevantExpressions(expressions, {
    columnProfile, filterProfile, aggregateProfile, orderby: recordsOrderby
  });

  const [stats, setStats] = useState({});  // The grid dataSource will set this for us to use
  const [records, setRecords] = useState([]); // this is just for the bar chart. Maybe we can do this better? AG will set.
  const [fetchingStatus, setFetchingStatus] = useState(FETCHING_STATUS.FETCHING); // the grid will set this

  const gridRef = useRef();
  const isMounted = useIsMountedRef();

  const oldestDateAvailable = useMemo(() => {
    getOldestAllowedTopListDate(permissions[TABS[currentTab].years_permissions])
  }, [currentTab]);

  const handleSetResizeKey = (resizeKey) => {
    applyResize(gridRef, resizeKey);
    dispatch(updateComponent(componentId, layoutId, {
      gridColumnSizeKey: resizeKey
    }));
  };

  const makeWatchlistMenu = useDefaultContextMenu({
    forceTicker: ticker,
    handleSetResizeKey,
  });


  const historyColumns = useMemo(() => {
    const gridColumns = buildGridColumns(columnProfile.columns, GRID_COLUMNS, relevantExpressions);
    let sortColIdx = gridColumns.findIndex(c => c.field === recordsOrderby);
    if (sortColIdx !== -1) {
      gridColumns[sortColIdx].sort = recordsOrder;
    } else {
      sortColIdx = gridColumns.findIndex(c => c.field === 'day0_date');
      gridColumns[sortColIdx].sort = 'desc';
      dispatch(updateComponent(componentId, layoutId, { recordsOrderby: 'day0_date', recordsOrder: 'desc' }));
    }
    return gridColumns;
  }, [columnProfile, relevantExpressions]);


  const [aggregateGroups, aggregateDefs] = useMemo(() => {
    return aggregateToDefinitions(aggregateProfile?.aggregates, GRID_COLUMNS, relevantExpressions);
  }, [aggregateProfile?.aggregates, relevantExpressions]);


  const memoizedDataSource = useMemo(() => {
    return new TopListHistoricalDataSource({ id: componentId });
  }, []);


  useDeepCompareEffect(() => {
    setTimeout(() => {
      if (isMounted.current && memoizedDataSource.initialRequestComplete && gridRef.current) {
        setFetchingStatus(FETCHING_STATUS.FETCHING);
        gridRef.current?.api.refreshServerSide({ route: [], purge: true });
      }
    }, 0);
  }, [ticker, columnProfile.columns, filterProfile.filters, aggregateProfile?.aggregates, relevantExpressions]);


  useEffect(() => {
    setTimeout(() => {
      if (isMounted.current && memoizedDataSource.initialRequestComplete && gridRef.current) {
        gridRef.current?.api.refreshServerSide({ route: [], purge: false });
      }
    }, 0)
  }, [recordsOrder, recordsOrderby]);


  const handleTabChange = () => {
    dispatch(updateComponent(componentId, layoutId, {
      currentTab: (currentTab + 1) % TABS.length
    }));
  };

  const handlePersistSort = ({ order: newOrder, orderby: newOrderby }) => {
    if (newOrder !== recordsOrder || newOrderby !== recordsOrderby) {
      dispatch(updateComponent(componentId, layoutId, {
        recordsOrder: newOrder,
        recordsOrderby: newOrderby
      }));
    }
  };

  const handleHistoryRowClicked = useCallback(({ data }) => {
    if (data?.day0_date) {
      // ticker is redundant here, but why not.
      const historicalDate = data?.day0_date === mostRecentTradingDayString ? false : data?.day0_date
      dispatchUpdateLinkedData({
        historicalDate,
        ticker
      });
    }
  }, [dispatchUpdateLinkedData, ticker]);


  const handleFilterSubmit = useCallback(({ expressions, ...newProfile }) => {
    dispatch(updateHistoryFilterProfiles(newProfile, layoutId, componentId, expressionPayload(expressions)));
  }, [layoutId, componentId]);


  const handleColumnSubmit = useCallback(({ expressions, ...newProfile }) => {
    dispatch(updateHistoryColumnProfiles(newProfile, layoutId, componentId, expressionPayload(expressions)));
  }, [layoutId, componentId]);


  const handleAggregateSubmit = useCallback(({ expressions, ...newProfile }) => {
    dispatch(updateHistoryAggregateProfiles(newProfile, layoutId, componentId, expressionPayload(expressions)));
  }, [layoutId, componentId]);


  const getRowId = useCallback(({ data }) => {
    return data.day0_date;
  }, []);


  const popupParent = useMemo(() => {
    return document.querySelector('body');
  }, []);


  const loading = fetchingStatus === FETCHING_STATUS.FETCHING;

  return (
    <MosaicPanel className={clsx(className, classes.root)}>
      <MosaicPanelHeader
        loading={loading}
        tickerSearchValue={ticker}
        onTickerSearchSubmit={(symbol) => dispatchUpdateLinkedData({ ticker: symbol })}
      >
        <IntercomArticleButton
          articleSlug={INTERCOM_SLUGS?.toplist?.components?.historical}
        />
        <HistoricalSlicedAggregatesForm
          profiles={aggregateProfileList}
          activeProfile={aggregateProfileId}
          expressions={expressions}
          onSubmit={handleAggregateSubmit}
          disabled={TABS[currentTab].name !== 'stats'}
        />
        <SlicedColumnsForm
          profiles={columnProfilesList}
          activeProfile={columnProfileId}
          expressions={expressions}
          onSubmit={handleColumnSubmit}
          disabled={TABS[currentTab].name !== 'records'}
        />
        <SlicedFiltersForm
          profiles={filterProfilesList}
          activeProfile={filterProfileId}
          expressions={expressions}
          onSubmit={handleFilterSubmit}
          oldestDateAvailable={oldestDateAvailable}
        />
      </MosaicPanelHeader>

      <LayoutActiveFilterBar
        className={classes.activeFilterBar}
        filterProfile={filterProfile}
        columnDefs={FILTER_COLUMNS}
        expressions={relevantExpressions}
        maxYears={permissions[TABS[currentTab].years_permissions]}
      // isFetching={fetchingStatus === FETCHING_STATUS.FETCHING}
      >
        <PanelIconButton
          Icon={TABS[currentTab].icon}
          className={classes.tabButton}
          onClick={handleTabChange}
          text={TABS[currentTab].label}
        />
      </LayoutActiveFilterBar>
      <MosaicPanelBody
        loading={loading}
        className={clsx(classes.scroll, 'ett-mosaic-scrollbar')}
      >
        <TabPanel value={currentTab} index={0}>
          {/* <TopListGapStats */}
          {/*   records={records} */}
          {/*   stats={stats} */}
          {/* /> */}
          <HistoricalAggregatesTable
            groups={aggregateGroups}
            defs={aggregateDefs}
            data={stats}
            records={records}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <div className={clsx(classes.gridContainer, 'ag-theme-ett', 'ag-theme-no-row-selection', `ag-grid-${componentId}`)}>
            <AgGridReact
              ref={gridRef}
              context={{
                componentId,
                columnProfile,
                filterProfile,
                aggregateProfile,
                setStats,
                setRecords,
                setFetchingStatus,
                ticker,
                order: recordsOrder,
                orderby: recordsOrderby,
                handlePersistSort,
                expressions,
                isMountedRef: isMounted
              }}
              columnDefs={historyColumns}
              components={{
                'compactNumberCellRenderer': CompactNumberCell,
              }}
              rowSelection={'single'}
              onRowSelected={(params) => onRowSelected(params, handleHistoryRowClicked)}
              onRowClicked={handleHistoryRowClicked}
              navigateToNextCell={handleKeyboardRowNavigation}
              serverSideInfiniteScroll={true}
              serverSideSortOnServer={true}
              suppressMultiSort={true}
              animateRows={false}
              headerHeight={25}
              popupParent={popupParent}
              getContextMenuItems={makeWatchlistMenu}
              getRowId={getRowId}
              sortingOrder={['desc', 'asc']}
              serverSideInitialRowCount={50} // Loading indicators. With infinite scroll we can't use the Overlay.
              cacheBlockSize={50}
              maxBlocksInCache={4}
              maxConcurrentDatasourceRequests={1}
              blockLoadDebounceMillis={600}
              rowModelType="serverSide"
              loadingCellRenderer={LoadingCellSkeleton}
              onGridReady={({ api }) => {
                setFetchingStatus(FETCHING_STATUS.FETCHING);
                api.setServerSideDatasource(memoizedDataSource);
                if (gridColumnSizeKey) {
                  applyResize(gridRef, gridColumnSizeKey);
                }
              }}
            />
          </div>
        </TabPanel>
      </MosaicPanelBody>
    </MosaicPanel>
  );
}

export default TopListHistorical;

