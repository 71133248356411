import React from 'react';
import clsx from 'clsx';
import { ARRAY_OP_LABELS } from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import SimpleDropdown from 'src/app/slicedForm/FilterForm/elements/SimpleDropdown';
import {
  Box,
  FormControl,
  makeStyles,
} from '@material-ui/core';
import useInputStyles, { ButtonStyleProps, InputStyleProps } from 'src/app/slicedForm/shared/styles/useInputStyles';
import { useEntity } from '../context/useFormEntity';
import SearchableMultiSelect from 'src/app/components/SearchableMultiSelect';
import { useColumnDef } from 'src/app/slicedForm/shared/context/ColumnDefsProvider';
import { useFilterEntityContext } from 'src/app/slicedForm/FilterForm/context/FilterEntityContext';
import { Switch, Case } from 'src/app/components/utility/SwitchCase';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  dropdown: {
    width: 250,
    '& > div': {
      padding: [[8, 8, 8, 8]],
      borderBottom: [['1px solid', theme.palette.background.paperAlt_highlight]]
    }
  },
  input: {
    width: '100% !important'
  }
}));


function MultiSelect({
  className,
  hideComparison
}) {
  const classes = useStyles();
  const inputClasses = useInputStyles();

  const { id, disabled } = useFilterEntityContext();
  const { useWatch, useRegister } = useEntity(id);

  const leftColumn = useWatch('left.column');
  const colDef = useColumnDef(leftColumn);
  const operatorInputProps = useRegister('operator', { refName: 'innerRef', valueName: 'selected' });
  const rightFirstValueProps = useRegister('right[0].value', { refName: 'innerRef', valueName: 'values' });
  
  let options = colDef?.options;
  if (!options || !Array.isArray(options) || !options.length) {
    let msg = `No options found for column '${leftColumn}'`;
    if (process.env.NODE_ENV === 'development') {
      throw Error(msg);
    } else {
      console.warn(msg);
    }
    options = [];
  }


  return (
    <Box className={clsx(className, classes.root)}>

      <Switch expr={hideComparison}>
        <Case when={true}>
          <FormControl className={clsx(
            inputClasses.formControl,
            inputClasses.comparisonSelectControl
          )}/>
        </Case>
        <Case default>
          <SimpleDropdown
            className={clsx(
              inputClasses.formControl,
              inputClasses.comparisonSelectControl
            )}
            options={ARRAY_OP_LABELS}
            label="Select comparison operator"
            disabled={disabled}
            {...operatorInputProps}
          />
        </Case>
      </Switch>

      <FormControl
        className={clsx(
          inputClasses.formControl,
          inputClasses.multiSelectInputControl,
          rightFirstValueProps?.error && inputClasses.formControlError,
          rightFirstValueProps?.error && 'sf-form-control-error-flash'
        )}
      >
        <SearchableMultiSelect
          ButtonProps={ButtonStyleProps}
          InputProps={InputStyleProps}
          buttonClassName={clsx(
            inputClasses.button,
            inputClasses.chipButton,
            inputClasses.highlight,
          )}
          inputClassName={clsx(
            inputClasses.input,
            inputClasses.highlight,
            classes.input
          )}
          disabled={disabled}
          menuClassName={classes.dropdown}
          placeholder={colDef?.label}
          options={options}
          limitTags={1}
          key={leftColumn}
          {...rightFirstValueProps}
        />
      </FormControl>
    </Box>
  )
}

export default MultiSelect;
