import React, { useMemo } from 'react';
import { ReactRenderer } from '@tiptap/react'
import ExpressionColumnSuggestionPopup from '../elements/ExpressionColumnSuggestionPopup';



/**
 * Returns the configuration for the column suggestion popup,
 * to be fed into tiptap@mention
 **/
export default function useSuggestion(colDefs, name = 'mention') {

  return useMemo(() => ({
    name,

    items: ({ query }) => colDefs.filter(col => col.name.toLowerCase().includes(query.toLowerCase())),

    allowSpaces: true,

    allowedPrefixes: ["a-zA-Z0-9@ \\(\\)\\[\\]+\\\\\\-\\\/\\*"], // eslint-disable-line

    command: ({ editor, range, props }) => {
      // Ensures a space is added before and after the @column.
      //
      // If a space already exists in the document before/after the new @column, then
      // we don't want to insert another
      //  "a@b" should become "a @col b".
      //  "a @ b" should NOT become "a  @col  b"
      const nodeBefore = editor.view.state.selection.$from.nodeBefore;
      const nodeAfter = editor.view.state.selection.$to.nodeAfter;

      const overrideSpaceBefore = nodeBefore?.text?.endsWith(' ');
      const overrideSpaceAfter = nodeAfter?.text?.startsWith(' ');

      if (overrideSpaceBefore) {
        range.from -= 1;
      }
      if (overrideSpaceAfter) {
        range.to += 1;
      }

      editor
        .chain()
        .focus()
        .insertContentAt(range, [
          {
            type: 'text',
            text: ' ',
          },
          {
            type: name,
            attrs: props,
          },
          {
            type: 'text',
            text: ' ',
          },
        ])
        .run()

      window.getSelection()?.collapseToEnd()

    },

    render: () => {
      let component;

      return {
        onStart: props => {
          component = new ReactRenderer(ExpressionColumnSuggestionPopup, {
            props,
            editor: props.editor,
          })
        },

        onUpdate(props) {
          component.updateProps(props)
        },

        onKeyDown(props) {
          console.log('ONKEYDOWN outer', props, props.event.key, component?.ref?.onKeyDown);
          return component.ref?.onKeyDown(props)
        },

        onExit() {
          setTimeout(() => {
            component.destroy()
          }, 0)
        },
      }
    },
  }), [colDefs])
}




