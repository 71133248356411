import React from 'react';
import clsx from 'clsx';
import {
  useFormSelector,
  useFormDispatch
} from 'src/app/slicedForm/shared/context/FormProvider';
import { getFilterEntityDefaults } from 'src/app/slicedForm/shared/schema/schemaBuilder';
import { CREATE_FILTER } from 'src/app/slicedForm/FilterForm/reducers/filterReducer';
import { useProfileLocked } from 'src/app/slicedForm/shared/context/ProfileLockedProvider';
import { selectActiveProfile } from 'src/app/slicedForm/shared/reducers/profileReducer';
import FilterGrouping from './FilterGrouping';
import Popover from 'src/app/slicedForm/FilterForm/elements/Popover';
import ColumnPickerForm, { useColumnPickerFormWidth } from 'src/app/slicedForm/FilterForm/elements/ColumnPickerForm';
import WithColumnPickerForm from './WithColumnPickerForm';
import {
  Box,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1.5),
    paddingLeft: 0 // Drag and drop
  },
  andGroupButton: {
    '&:focus-visible': {
      ...theme.focus.outline
    },
    borderBottomRightRadius: 4,
    marginLeft: 22.28, // Drag and drop
    '& .MuiButton-label': {
      paddingTop: 2,
      paddingLeft: 6,
      paddingRight: 6
    }
  },
  andGroup: {
    border: `1px solid ${theme.palette.background.lightBorder}`,
    backgroundColor: theme.palette.background.paperAlt_darker1,
    borderRadius: 4,
    borderBottomRightRadius: 0,
    position: 'relative',
  },
}));




/**
 * Mount-point for <FilterGrouping /> recursive tree.
 * Represents the Filters section of the form.
 */
function FiltersSection({ className }) {
  const classes = useStyles();
  const dispatch = useFormDispatch();
  const { locked } = useProfileLocked();
  const profile = useFormSelector(selectActiveProfile);

  if (!profile?.root) {
    return <Typography>No active profile...</Typography>;
  }

  const handleAddFilter = (colDef) => {
    // TODO: This is hard-coding. We probably want this dynamic. Definitely pass it through context.
    const defaults = getFilterEntityDefaults(colDef);
    if (!defaults || !colDef) {
      console.warn(`No defaults/colDef found for column ${colDef}}`);
      return;
    }

    dispatch({
      type: CREATE_FILTER,
      payload: {
        groupId: 'ROOT',
        data: defaults
      }
    })
  };

  return (
    <div className={clsx(className, classes.root)}>
      <FilterGrouping
        type={profile.root.type}
        tree={profile.root.tree}
        andGroupStyles={classes.andGroup}
      />
      <Box>
        <WithColumnPickerForm
          anchorPosition="left"
          onSelect={handleAddFilter}
        >
          <Button
            disableRipple
            disableFocusRipple
            className={clsx(
              classes.andGroup,
              classes.andGroupButton
            )}
            disabled={locked}
          >
            + Add Filter
          </Button>
        </WithColumnPickerForm>
      </Box>
    </div>
  );
}

export default FiltersSection;
