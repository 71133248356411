import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { useColumnGroups, useColumnDefs } from "src/app/slicedForm/shared/context/ColumnDefsProvider";
import { metricsInGroup } from "src/hooks/useFilterFormMetrics";
import ActionList from 'src/app/components/ActionList';
import {
  Box,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    padding: [[theme.spacing(1), 0]]
  },
  profileItem: {
    '& p': {
      padding: 0,
      margin: 0,
    },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.1)',
      cursor: 'pointer',
    },
    '&.-active': {
      backgroundColor: `rgba(100, 0, 100, .1)`,
      color: 'purple'
    }
  }
}))




function ProfilesSection({
  className,
  selected,
  onSelect,
  includeAllCategory,
  includeExpressionsCategory,
}) {
  const colDefs = useColumnDefs();
  const theme = useTheme();
  const classes = useStyles();
  const groups = useColumnGroups();

  const nonEmptyGroups = Object.values(groups).filter(group => {
    const metrics = metricsInGroup(group.name, colDefs);
    return metrics.length;
  })

  return (
    <ActionList
      className={clsx(className, classes.root)}
    >
      {includeAllCategory && (
        <ActionList.Item
          pl={theme.spacing(2)}
          key='ALL'
          selected={'ALL' === selected}
          role="menuitem"
          dense
          onSelect={() => onSelect('ALL')}
        >
          All
        </ActionList.Item>
      )}
      {includeExpressionsCategory && (
        <ActionList.Item
          pl={theme.spacing(2)}
          key='EXPRESSION'
          selected={'EXPRESSION' === selected}
          role="menuitem"
          dense
          onSelect={() => onSelect('EXPRESSION')}
        >
          Expressions
        </ActionList.Item>
      )}
      {nonEmptyGroups.map(group => (
        <ActionList.Item
          pl={theme.spacing(2)}
          key={group.name}
          dense
          selected={group.name === selected}
          onSelect={() => onSelect(group.name)}
        >
          {group.label}
        </ActionList.Item>
      ))}
    </ActionList>
  )
}

export default ProfilesSection;
