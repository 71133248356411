import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { animated, useSpring, } from 'react-spring';
import {
  ArrowUpward as ArrowUpIcon,
  ArrowDownward as ArrowDownIcon
} from '@material-ui/icons';
import {
  Box, Button,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

const OPEN_BUTTON_WIDTH = 40;

const useStyles = makeStyles(theme => ({
  columnPadding: {
    position: 'relative',
    width: OPEN_BUTTON_WIDTH
  },
  root: {
    display: 'flex',
    position: 'absolute',
    zIndex: props => props.zIndex,
    top: 0,
    bottom: 0,
    '& .FormModal-Column': {
      width: props => props.width,
    },
  },
  openSidebar: {
    width: OPEN_BUTTON_WIDTH,
    minWidth: OPEN_BUTTON_WIDTH,
    maxWidth: OPEN_BUTTON_WIDTH,
    height: '100%',
    padding: 0,
    borderRight: `1px solid ${theme.palette.background.panelBorder}`,
  },
  rotate: {
    padding: 0,
    margin: 0,
    transform: 'rotate(270deg)',
    display: 'flex',
    // display: 'inline-block',
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transformOrigin: 'center center',
    // width: '100%',
    // textAlign: 'center',
    // marginTop: -10,
    // marginLeft: -10,
    // fontSize: 20,
    // fontWeight: 700,
    // color: theme.palette.text.primary,
    // zIndex: 2,
  },
  background: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
  },
  // content: {
  //   flex: 1,
  //   display: 'flex',
  //   flexDirection: 'column',
  //   padding: 0,
  // },
  title: {
    backgroundColor: 'lightgray'
  },
  divider: {
    borderRight: `2px solid ${theme.palette.background.panelBorder}`,
    position: 'relative',
    width: 30,
    backgroundColor: theme.palette.background.panelHeader
  },
}));


const animConfig = {
  mass: 1,
  friction: 18,
  tension: 140,
  bounce: 0,
};



function Drawer({
  className,
  children,
  breakpoint,
  width,
  zIndex,
}) {
  const classes = useStyles({ zIndex, width });
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(breakpoint), { noSsr: true });
  const [open, setOpen] = useState(false);

  const [drawerProps, api] = useSpring(() => ({
    width: width + OPEN_BUTTON_WIDTH,
    right: open ? -(width) : 0,
    config: animConfig,
  }), [width, open]);

  const handleOpen = (nextOpen) => {
    setOpen(nextOpen);
  };

  if (!isSmall) {
    return (
      <div style={{ width }}>{children}</div>
    );
  }

  return (
    <>
      <div className={classes.columnPadding}>
        <animated.div
          className={clsx(className, classes.root)}
          style={drawerProps}
        >
          {children}
          <Button
            arian-label="Open sidebar"
            className={classes.openSidebar}
            onClick={() => handleOpen(!open)}
          >

            <Typography className={classes.rotate}>
              Profiles
              {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </Typography>
          </Button>
        </animated.div>
      </div>
      {open && (
        <div
          className={classes.background}
          onClick={() => handleOpen(false)}
        />
      )}
    </>
  );
}


Drawer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zIndex: PropTypes.number,
  expand: PropTypes.bool,
  breakpoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  /** For react-spring use */
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
};


Drawer.defaultProps = {
  style: {},
  breakpoint: 'md',
  zIndex: 2
};


export default Drawer;

