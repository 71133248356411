import React, { useEffect, useMemo } from 'react';
import _noop from 'lodash/noop';
import PropTypes from 'prop-types'
import clsx from 'clsx';
import GapStatsBarChart from './GapStatsBarChart';
import TwoColDivTable from 'src/app/components/tables/TwoColDivTable';
import { makeStyles, useTheme, darken } from '@material-ui/core';
import { formatPercentage } from 'src/utils/formatters';
import { AGGREGATE_SHORT_LABELS, AGGREGATES } from 'src/app/slicedForm/FilterForm/definitions/inputEnums';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 4,
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  column: {
    padding: '0 4px',
    flex: 1,
    minWidth: 220
  },
  columnLarge: {
    flexBasis: 390,
  },
  table: {
    marginTop: 8,
    '& .MuiTypography-h3': {
      padding: '0 0.3em 0.3em',
      paddingLeft: 6,
      fontSize: 17
    },
    '& .MuiTypography-body2': {
      whiteSpace: 'nowrap'
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      '& .makeStyles-cellRight-66': {
        textAlign: 'right !important'
      }
    }
  }
}));


// TODO: This is uneeded, we should change it to be flat array of metrics
const barChartSeriesConfig = [
  {
    name: 'PMH %',
    operation: (e) => e['day0_pm_high_pct']
  },
  {
    name: 'Gap %',
    operation: (e) => e['day0_gap_pct']
  },
  {
    name: 'High %',
    operation: (e) => e['day0_close_high_pct']
  },
  {
    name: 'Close %',
    operation: (e) => e['day0_chg_pct']
  },
];


const percentage = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 1,
  minimumFractionDigits: 0
}).format;


const defaultFmt = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
}).format;


const guard = (formatter) => {
  return (val) => {
    if (val === null || val === undefined) {
      return '-'
    }
    return formatter(val);
  }
}


function HistoricalAggregatesTable({
  className,
  groups,
  defs,
  data,
  records,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const tables = useMemo(() => {
    const defaultTagColor = darken(theme.palette.text.secondary, .1);
    const mapMetric = (name) => {
      const value = data?.[name];
      const {
        formatter = defaultFmt,
        label = name,
        conditional_agg
      } = defs?.[name] || {};

      const _fmt = guard(formatter);

      const base = {
        label,
        value: _fmt(value),
        tag: AGGREGATE_SHORT_LABELS[conditional_agg],
        tagColor: theme.palette.aggregates?.[conditional_agg] || defaultTagColor
      }

      if (conditional_agg === AGGREGATES.CNT && data[`${name}__pct`] && (value !== null && value !== undefined)) {
        const pct = data[`${name}__pct`];
        base.secondary = percentage(pct / 100);
      }

      return base;
    }

    return groups.map(group => {
      return {
        label: group.label,
        data: group.data.map(mapMetric)
      }
    });
  }, [groups, defs, data]);

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        {tables.map((table, idx) => (
          <div key={idx} className={classes.column}>
            <TwoColDivTable dense title={table.label} data={table.data} className={classes.table} align="right" />
          </div>
        ))}
      </div>
      <div className={classes.row}>
        <div className={clsx(classes.column, classes.columnLarge)}>
          <GapStatsBarChart timeSeriesData={records} operations={barChartSeriesConfig} height={199} />
        </div>
      </div>
    </div>
  );
}


HistoricalAggregatesTable.propTypes = {
  className: PropTypes.string,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  defs: PropTypes.shape({
    formatter: PropTypes.func,
    label: PropTypes.string,
    name: PropTypes.string,
    original: PropTypes.string,
    conditional_agg: PropTypes.string
  }),
  data: PropTypes.object
}


export default HistoricalAggregatesTable;
