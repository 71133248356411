import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  getMostRecentTradingDay,
  formatMarketTime
} from 'src/utils/datetime/date-fns.tz';
import { isValid } from 'date-fns';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { CHART_DATE_INITIALIZATION_TYPES } from 'src/redux/layout/topListLayoutSchema';
import PopoverKeyboardDatePicker from 'src/app/components/pickers/PopoverKeyboardDatePicker';
import { LinkingIcon } from 'src/theme/EdgeIcons';
import { useDropdownStyles } from 'src/app/TopListsMosaic/style/useDropdownStyles';
import {
  makeStyles,
  Box,
  Button,
  Typography
} from '@material-ui/core';
import { makeStaticDaysTopList } from 'src/app/components/pickers/definitions/staticDayDefinitions';


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  datePicker: {
    marginLeft: 16,
    '& .MuiIconButton-root': {
      padding: 2,
      marginLeft: 6,
      borderRadius: 4,
    },
    '& .MuiInputBase-input': {
      width: 80,
      paddingTop: 5,
      paddingBottom: 3,
      fontSize: 13,
      '&:before': {
        content: 'none'
      }
    }
  },
  toggleButtonGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
}));


function DateLinkDropdown({
  className,
  toggleValue,
  selectedDay,
  onToggleChange,
  onDatePickerAccept,
  onGoButtonClick
}) {
  const classes = useStyles();
  const dropdownClasses = useDropdownStyles();
  const [inputDay, setInputDay] = useState(() => selectedDay
    ? new Date(selectedDay)
    : getMostRecentTradingDay()
  );

  const handlePickerAccept = (date) => {
    if (isValid(date)) {
      setInputDay(date)
      onDatePickerAccept(formatMarketTime(date, 'yyyy-MM-dd'))
    }
  }

  return (
    <div className={clsx(className, classes.root)}>
      <ToggleButtonGroup
        className={classes.toggleButtonGroup}
        value={toggleValue}
        exclusive
        onChange={onToggleChange}
        aria-label="Choose what date the chart will display by default when selecting a new ticker"
      >
        <ToggleButton
          className={dropdownClasses.dropdownButton}
          value={CHART_DATE_INITIALIZATION_TYPES.LINKED_DATE}
          disableRipple
          disableFocusRipple
        >
          <Box display="flex" alignItems="center">
            <LinkingIcon />
            <Typography variant="body">Linked Date</Typography>
          </Box>
        </ToggleButton>
        <ToggleButton
          className={dropdownClasses.dropdownButton}
          value={CHART_DATE_INITIALIZATION_TYPES.TODAY}
          disableRipple
          disableFocusRipple
        >
          <Box display="flex" alignItems="center">
            <Typography variant="body">Today</Typography>
          </Box>
        </ToggleButton>
        <ToggleButton
          className={dropdownClasses.dropdownButton}
          value={CHART_DATE_INITIALIZATION_TYPES.SELECT_DATE}
          disableRipple
          disableFocusRipple
        >
          <Box display="flex" alignItems="center">
            <Typography variant="body" style={{ paddingTop: 1 }}>Select Date:</Typography>
            <PopoverKeyboardDatePicker
              inputClassName={classes.datePicker}
              date={inputDay}
              format="yyyy-MM-dd"
              placeholder={'yyyy-mm-dd'}
              disableFuture
              disableHoliday
              disableWeekend
              marketTime
              onAccept={handlePickerAccept}
              makeStaticRanges={makeStaticDaysTopList}
              disableUnderline
              showErrorMessage={false}
            />
          </Box>
        </ToggleButton>
      </ToggleButtonGroup>
      <Button
        className={dropdownClasses.goButton}
        disableRipple
        disableTouchRipple
        variant="outlined"
        color="primary"
        onClick={onGoButtonClick}
      >
        Go
      </Button>
    </div>
  );
}


DateLinkDropdown.propTypes = {
  className: PropTypes.string,
  toggleValue: PropTypes.string,
  inputDay: PropTypes.string,
  onToggleChange: PropTypes.func,
  onDateChange: PropTypes.func,
  onGoButtonClick: PropTypes.func
};


export default DateLinkDropdown;
