import React, { useMemo, useState } from 'react';
import {
  FILTER_COLUMNS,
  GROUPS,
} from 'src/app/components/grid/topListScanner/columns/columnDefs';
import { PROFILE_CONFIG } from 'src/redux/layout/topListLayoutSchema';
import profileReducer, {
  ADD_PROFILE,
  COPY_PROFILE,
  generateId
} from 'src/app/slicedForm/shared/reducers/profileReducer';
import { formToProfile, profileToForm, FILTER_FORM_TYPES } from 'src/app/slicedForm/mapping/mappingDirections/index';
import FormProvider from 'src/app/slicedForm/shared/context/FormProvider';
import Form from 'src/app/slicedForm/FilterForm/elements/Form';
import ColumnDefsProvider from 'src/app/slicedForm/shared/context/ColumnDefsProvider';
import ProfileLockedProvider from 'src/app/slicedForm/shared/context/ProfileLockedProvider';
import filterReducer from 'src/app/slicedForm/FilterForm/reducers/filterReducer';
import expressionReducer from 'src/app/slicedForm/shared/reducers/expressionReducer';
import FormModal from 'src/app/components/FormModal';
import PropTypes from 'prop-types';
import PanelIconButton from 'src/app/components/panels/PanelIconButton';
import { FilterIcon } from 'src/theme/EdgeIcons';
import {
  SCANNER_FUSE_OPTIONS
} from 'src/app/slicedForm/shared/schema/synonyms';



const serialize = (state) => profileToForm(state, FILTER_FORM_TYPES.FILTER);
const deserialize = (state) => formToProfile(state, FILTER_FORM_TYPES.FILTER);



function SlicedFiltersForm({
  profiles,
  activeProfile,
  expressions,
  oldestDateAvailable,
  onSubmit,
}) {

  const [open, setOpen] = useState(false);

  const initialState = useMemo(() => {
    return { profiles, activeProfile, expressions };
  }, [profiles, activeProfile, expressions]);

  const reducers = useMemo(() => {
    /** 
    ORDER MATTERS! Some actions must modify both reducers. In that case, the data will be operated on 
    in the specified order. They share an Immer object. Example:
    COPY_PROFILE: 
      1) Profile reducer makes the copy
      2) Filter reducer re-assings the IDs within the copy
    */
    return [profileReducer, filterReducer, expressionReducer];
  }, []);

  const formActions = useMemo(() => ({
    onSubmit: ({ state }) => {
      onSubmit(deserialize(state));
      setOpen(false);
    },
    onSave: ({ state }) => {
      onSubmit(deserialize(state));
    },
    onCancel: () => {
      setOpen(false);
    },
    onCreate: ({ dispatch }) => {
      // Can't use defaults because its not serialized.
      // Just copy the serialized one.
      const idToCopy = PROFILE_CONFIG.SCANNER_FILTERS.predefinedProfiles[0].id;
      dispatch({
        type: ADD_PROFILE, payload: {
          id: idToCopy,
          newId: generateId()
        }
      });
    },
    onCopy: ({ state, dispatch }) => {
      const id = state?.activeProfile;
      dispatch({
        type: COPY_PROFILE, payload: {
          id,
          newId: generateId()
        }
      });
    }
  }), []);

  // Individual ColumnDefs can override these
  const defaultDateConfig = useMemo(() => {
    return {
      minDate: oldestDateAvailable,
      disableFuture: true,
      disableWeekend: true,
      disableHoliday: true,
      makeRollingDates: undefined,
      makeInputDates: undefined,
      makeStaticDates: undefined,
      staticRangeHeaderContent: undefined,
      format: 'yyyy-MM-dd',
      placeholder: 'yyyy-mm-dd'
    }
  }, [oldestDateAvailable])


  // The Context must be within Modal. Otherwise, reducer state will not re-initialize on open.
  return (
    <>
      <PanelIconButton
        Icon={FilterIcon}
        onClick={() => setOpen(true)}
        text="Filters"
        shouldHideIconText={true}
      />
      <FormModal
        open={open}
        width={1108}
        height={900}
        onClose={formActions.onCancel}
      >
        <FormProvider
          initialState={initialState}
          reducers={reducers}
          initFunc={serialize}
          actions={formActions}
        >
          <ColumnDefsProvider
            columnDefs={FILTER_COLUMNS}
            columnGroups={GROUPS}
            fuseOptions={SCANNER_FUSE_OPTIONS}
            defaultDateConfig={defaultDateConfig}
          >
            <ProfileLockedProvider>
              <Form />
            </ProfileLockedProvider>
          </ColumnDefsProvider>
        </FormProvider>
      </FormModal>
    </>
  );
}


SlicedFiltersForm.propTypes = {
  profiles: PropTypes.array.isRequired,
  activeProfile: PropTypes.string.isRequired,
  expressions: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired,
};

SlicedFiltersForm.defaultProps = {
  expressions: [],
}


export default React.memo(SlicedFiltersForm);
