import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  COLUMN_COLUMNS,
  GROUPS,
} from 'src/app/components/grid/topListHistorical/columns/columnDefs';
import {
  SCANNER_FUSE_OPTIONS
} from 'src/app/slicedForm/shared/schema/synonyms';
import { PROFILE_CONFIG } from 'src/redux/layout/topListLayoutSchema';
import profileReducer, {
  ADD_PROFILE,
  COPY_PROFILE,
  generateId
} from 'src/app/slicedForm/shared/reducers/profileReducer';
import ProfileLockedProvider from 'src/app/slicedForm/shared/context/ProfileLockedProvider';
import FormProvider from 'src/app/slicedForm/shared/context/FormProvider';
import Form from 'src/app/slicedForm/ColumnForm/elements/Form';
import ColumnDefsProvider from 'src/app/slicedForm/shared/context/ColumnDefsProvider';
import columnReducer from 'src/app/slicedForm/ColumnForm/reducers/columnReducer';
import expressionReducer from 'src/app/slicedForm/shared/reducers/expressionReducer';
import FormModal from 'src/app/components/FormModal';
import PanelIconButton from 'src/app/components/panels/PanelIconButton';
import { ColumnsIcon } from 'src/theme/EdgeIcons';
import HistoricalSlicedFiltersForm from './HistoricalSlicedFiltersForm';




/**
 * Container component for Modal and Button.
 *
 * Rather than customizable props, just specify Icon and similar here.
 * It's easy to make copies of these.
 *
 * @component
 */
function HistoricalSlicedColumnsForm({
  profiles,
  activeProfile,
  expressions,
  onSubmit,
  disabled,
}) {
  const [open, setOpen] = useState(false);

  const initialState = useMemo(() => {
    return { profiles, activeProfile, expressions };
  }, [profiles, activeProfile, expressions]);

  const reducers = useMemo(() => {
    /** The order might matter if you respond to a single action in both reducers simultaniously! */
    return [profileReducer, columnReducer, expressionReducer];
  }, []);

  const formActions = useMemo(() => ({
    onSubmit: ({ state }) => {
      onSubmit(state);
      setOpen(false);
    },
    onSave: ({ state }) => {
      onSubmit(state);
    },
    onCancel: () => {
      setOpen(false);
    },
    onCreate: ({ dispatch }) => {
      dispatch({
        type: ADD_PROFILE, payload: {
          base: PROFILE_CONFIG.HISTORY_COLUMNS.predefinedProfiles[0],
          newId: generateId()
        }
      });
    },
    onCopy: ({ state, dispatch }) => {
      const id = state?.activeProfile;
      dispatch({
        type: COPY_PROFILE, payload: {
          id,
          newId: generateId()
        }
      });
    }
  }), []);

  return (
    <>
      <PanelIconButton
        Icon={ColumnsIcon}
        onClick={() => setOpen(true)}
        text="Columns"
        shouldHideIconText={true}
        disabled={disabled}
      />
      <FormModal
        open={open}
        width={900}
        height={1400}
        onClose={formActions.onCancel}
      >
        <FormProvider
          initialState={initialState}
          reducers={reducers}
          actions={formActions}
        >
          <ColumnDefsProvider
            columnDefs={COLUMN_COLUMNS}
            columnGroups={GROUPS}
            fuseOptions={SCANNER_FUSE_OPTIONS}
          >
            <ProfileLockedProvider>
              <Form />
            </ProfileLockedProvider>
          </ColumnDefsProvider>
        </FormProvider>
      </FormModal>
    </>
  );
}


HistoricalSlicedColumnsForm.propTypes = {
  profiles: PropTypes.array.isRequired,
  activeProfile: PropTypes.string.isRequired,
  expressions: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

HistoricalSlicedColumnsForm.defaultProps = {
  expressions: []
}

export default React.memo(HistoricalSlicedColumnsForm);
