import * as Sentry from '@sentry/react';
import React, { useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Subscription from 'src/services/Subscription';

export const MAX_YEARS = 14;

const plan_level_heirarchy = ['basic', 'pro', 'pro_plus'];

export const isNextPlanLevelDowngrade = (currentPlanLevel, nextPlanLevel) => {
  let currplan = currentPlanLevel;
  if (currentPlanLevel === 'test') {
    currplan = 'pro_plus'
  }

  return plan_level_heirarchy.indexOf(currplan) > plan_level_heirarchy.indexOf(nextPlanLevel);
}

export const MAX_EXPRESSIONS_LIMIT = 15;

export const permissions = {
  basic: {
    history_max_years: 1,
    gap_stats_max_years: MAX_YEARS,
    scanner_seconds: 5, // UNUSED
    history_download_max_columns: 0,
    history_download_max_rows: 0,
    scanner_copy_paste: false,
    toplist_layout_max_scanners: 1,
    toplist_layout_max_watchlists: 1,
    toplist_layout_max_charts: 2,
    toplist_layout_default_max_component: 10,
    max_expressions: 3,
    max_expressions__can_update: true,
  },
  pro: {
    history_max_years: MAX_YEARS,
    gap_stats_max_years: MAX_YEARS,
    scanner_seconds: 5, // UNUSED
    history_download_max_columns: 0,
    history_download_max_rows: 0,
    scanner_copy_paste: false,
    toplist_layout_max_scanners: Infinity,
    toplist_layout_max_watchlists: Infinity,
    toplist_layout_max_charts: Infinity,
    toplist_layout_default_max_component: 10,
    max_expressions: MAX_EXPRESSIONS_LIMIT,
    max_expressions__can_update: false,
  },
  pro_plus: {
    history_max_years: MAX_YEARS,
    gap_stats_max_years: MAX_YEARS,
    history_download_max_columns: 60,
    history_download_max_rows: 2000,
    scanner_seconds: 5, // UNUSED
    scanner_copy_paste: true,
    toplist_layout_max_scanners: Infinity,
    toplist_layout_max_watchlists: Infinity,
    toplist_layout_max_charts: Infinity,
    toplist_layout_default_max_component: 10,
    max_expressions: MAX_EXPRESSIONS_LIMIT,
    max_expressions__can_update: false,
  },
  test: {
    history_max_years: MAX_YEARS,
    gap_stats_max_years: MAX_YEARS,
    history_download_max_columns: 60,
    history_download_max_rows: 2000,
    scanner_seconds: 5, // UNUSED
    scanner_copy_paste: true,
    toplist_layout_max_scanners: Infinity,
    toplist_layout_max_watchlists: Infinity,
    toplist_layout_max_charts: Infinity,
    toplist_layout_default_max_component: 10,
    max_expressions: MAX_EXPRESSIONS_LIMIT,
    max_expressions__can_update: false,
  }
};

// if (process.env.REACT_APP_USERS_LAMBDA_STAGE !== 'prod') {
//   console.warn('Modifying pro_plus permissions in development environment.')
//   // permissions.pro_plus['gap_stats_max_years'] = 1;
//   permissions.pro_plus['history_max_years'] = 1;
// }

// const trialingExceptions = {
//   history_download_max_rows: 10
// }

let userDataUploadedTime = 0;
const shouldAttachUserDocument = () => {
  const should = (+ Date.now()) - userDataUploadedTime > 60;
  if (should) {
    userDataUploadedTime = + Date.now();
  }
  return should;
}


const logSentry = (msg, { user = {}, planLevel, scope, scopes, selectedPermission }) => {
  Sentry.withScope(sentryScope => {
    sentryScope.setTransactionName('useUserPlanPermissions permissions error');
    sentryScope.setExtras({ planLevel, scope, scopes, selectedPermission });
    if (shouldAttachUserDocument()) {
      userDataUploadedTime = + Date.now();
      const filename = user?.userSub ? `${user?.userSub}.json` : 'unknown.json';
      sentryScope.addAttachment({
        data: JSON.stringify(user),
        contentType: 'application/json',
        filename
      });
    }
    Sentry.captureException(new Error(msg))
  })
}


function useUserPlanPermissions(scopes) {
  const user = useSelector(state => state.account.user);
  const subscription = useMemo(() => new Subscription(user, user.subscriptionId), [user]);
  const lastPlanLevel = useRef();

  return useMemo(() => {
    const validPermissions = {};

    let planLevel;
    if (user.betaAccess && process.env.REACT_APP_USERS_LAMBDA_STAGE) {
      planLevel = 'pro_plus'
    } else {
      planLevel = subscription.plan_level;
    }

    if (lastPlanLevel.current !== planLevel) {
      lastPlanLevel.current = planLevel;
      if (planLevel) {
        Sentry.setTag('plan.level', planLevel);
      }
    }

    const selectedPermission = permissions?.[planLevel];

    if (!selectedPermission) {
      logSentry('useUserPlanPermissions error, permission not found', { user, planLevel, scopes, selectedPermission });
      throw Error(`ett permissions error, permission not found for ${scopes} ${planLevel}`)
    }

    scopes.forEach(scope => {
      if (!(scope in selectedPermission)) {
        logSentry('useUserPlanPermissions error, scope not allowed', { user, planLevel, scopes, scope, selectedPermission });
        throw Error('ett permissions error, scope not allowed')
      } else {
        validPermissions[scope] = selectedPermission[scope];
      }
    })


    return { planLevel, ...validPermissions };

  }, [subscription, ...scopes]);
}


export default useUserPlanPermissions;
