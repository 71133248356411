import React, { useContext } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import useUserPlanPermissions from 'src/hooks/useUserPlanPermissions';
import { MosaicWindowContext } from 'react-mosaic-component';
import { selectActiveLayoutComponentTypeCount } from 'src/redux/layout/topListLayoutSelectors';
import { COMPONENT_MAP, AVAILABLE_COMPONENTS, COMPONENT_TYPES } from './components';
import MosaicPanel from 'src/app/TopListsMosaic/layout/MosaicPanel';
import MosaicPanelHeader from 'src/app/TopListsMosaic/layout/MosaicPanelHeader/MosaicPanelHeader';
import MosaicPanelBody from 'src/app/TopListsMosaic/layout/MosaicPanelBody';
import ConditionalWrapper from 'src/app/components/utility/ConditionalWrapper';
import DarkTooltip from 'src/app/components/utility/DarkTooltip';
import {
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    backgroundColor: theme.palette.background.fixedTableHeader,
    color: theme.palette.text.primary,
    padding: '10px 5px',
    borderRadius: theme.grid.borderRadius,
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.paperAlt,
    },
    '& .MuiSvgIcon-root': {
      margin: 0,
    },
    '& p': {
      marginLeft: theme.spacing(.5),
    }
  },
  disabled: {
    '& $button': {
      opacity: 0.5,
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: theme.palette.background.fixedTableHeader,
      },
    }
  },
  tooltipLink: {
    fontSize: '13px !important',
    color: theme.palette.text.primary
  },
  gridRestrict: {
    maxWidth: 390,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  scroll: {
    height: '100%',
    overflowY: 'auto',
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: -theme.spacing(1)
  },
  gridItem: {
    flexBasis: '50%',
    minWidth: 156,
    padding: theme.spacing(1),
    flex: 1
  },
  gridItemFullWidth: {
    flexBasis: '100% !important'
  }
}));


function UpgradeAccountTooltip({ componentTitle, limit, children }) {
  const classes = useStyles();
  return (
    <DarkTooltip
      interactive
      enterDelay={0}
      leaveDelay={300}
      title={(
        <Link
          to="/account/subscription/update"
          component={RouterLink}
          className={classes.tooltipLink}
          underline="none"
        >
          Limit of {limit} {componentTitle} component per layout.<br />
          <u>Upgrade your account</u> to increase.
        </Link>
      )}
    >
      {children}
    </DarkTooltip>
  );
}


function DefaultMaxReachedTooltip({ componentTitle, children }) {
  const classes = useStyles();
  return (
    <DarkTooltip
      enterDelay={0}
      leaveDelay={0}
      title={(
        <Typography variant="body1" className={classes.tooltipLink}>
          Maximum {componentTitle} components reached<br />
        </Typography>
      )}
    >
      {children}
    </DarkTooltip>
  );
}



function ComponentSelectPanel({ className, panelHeaderProps, onComponentSelectOverride }) {
  const classes = useStyles();
  const mosaicWindowContext = useContext(MosaicWindowContext);
  const {
    toplist_layout_max_scanners,
    toplist_layout_max_watchlists,
    toplist_layout_max_charts,
    toplist_layout_default_max_component
  } = useUserPlanPermissions(['toplist_layout_max_scanners', 'toplist_layout_max_watchlists', 'toplist_layout_max_charts', 'toplist_layout_default_max_component']);
  const componentTypeMaxAllowed = {
    [COMPONENT_TYPES.SCANNER]: toplist_layout_max_scanners,
    [COMPONENT_TYPES.CHART]: toplist_layout_max_charts,
    [COMPONENT_TYPES.WATCHLIST]: toplist_layout_max_watchlists
  };
  const componentTypeCount = useSelector(selectActiveLayoutComponentTypeCount);

  return (
    <MosaicPanel className={className}>
      <MosaicPanelHeader
        loading={false}
        title="Select Component"
        showLinkingDropdownMenu={false}
        {...panelHeaderProps}
      />
      <MosaicPanelBody
        loading={false}
        className={clsx(classes.gridRestrict, classes.scroll, 'ett-mosaic-scrollbar')}
      >
        <div className={classes.gridContainer}>
          {AVAILABLE_COMPONENTS.map(type => {
            const info = COMPONENT_MAP[type];
            const Icon = info.Icon;
            const disabledMaxReachedForPlan = (componentTypeCount?.[type] >= componentTypeMaxAllowed?.[type]) || false;
            const disabledMaxReachedDefault = (componentTypeCount?.[type] >= toplist_layout_default_max_component) || false;
            const disabled = disabledMaxReachedForPlan || disabledMaxReachedDefault;
            return (
              <ConditionalWrapper
                key={type}
                condition={disabled}
                wrapper={(children) => {
                  if (disabledMaxReachedForPlan) {
                    return <UpgradeAccountTooltip limit={componentTypeMaxAllowed[type]} componentTitle={info.title}>{children}</UpgradeAccountTooltip>
                  }
                  else if (disabledMaxReachedDefault) {
                    return <DefaultMaxReachedTooltip componentTitle={info.title}>{children}</DefaultMaxReachedTooltip>
                  }
                }}
              >
                <div
                  key={type}
                  className={clsx(
                    classes.gridItem,
                    info?.fullwidth && classes.gridItemFullWidth,
                    disabled && classes.disabled
                  )}
                >
                  <a
                    role="button"
                    className={classes.button}
                    aria-label="Add Component"
                    aria-disabled={disabled}
                    onClick={() => {
                      if (disabled) return;
                      const args = { type, overrides: { title: info.title } };
                      onComponentSelectOverride
                        ? onComponentSelectOverride(args)
                        : mosaicWindowContext && mosaicWindowContext.mosaicWindowActions.replaceWithNew(args);
                    }}
                  >
                    <Icon />
                    <Typography>{info.title}</Typography>
                  </a>
                </div>
              </ConditionalWrapper>
            );
          })}
        </div>
      </MosaicPanelBody>
    </MosaicPanel>
  );
}

export default ComponentSelectPanel;
