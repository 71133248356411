export const DAY_LABELS = {
  avg_high_time: 'Avg. HOD',
  avg_low_time: 'Avg. LOD',
  avg_gap: 'Avg. Gap',
  close_green: 'Close Green',
  close_green_pct: 'Close Green %',
  close_red: 'Close Red',
  close_red_pct: 'Close Red %',
  open_level_green: 'Open Green' ,
  open_level_green_pct: 'Open Green %',

  close_gt_open: 'Close > Open',
  close_gt_open_comp: 'Close > Open',
  close_lt_open: 'Close < Open',
  close_lt_open_comp: 'Close < Open',
  close_gt_prev_close: 'Close > Prev. Close',
  close_gt_prev_close_comp: 'Close > Prev. Close',
  close_lt_prev_close: 'Close < Prev. Close',
  close_lt_prev_close_comp: 'Close < Prev. Close',

  open_level_red: 'Open Red',
  open_level_red_pct: 'Open Red %',
  high_spike: 'High Spike',
  low_spike: 'Low Spike',
  avg_range: 'Avg. Range',
  gap_up_3: 'Gap Up 3%',
  gap_down_3: 'Gap Down 3%',
  avg_vol: 'Avg. Volume',
  avg_pmh_time: 'Avg. PM High Time',
  avg_pm_vol: 'Avg. PM Volume',

  avg_gap_comp: 'Avg. Gap',
  avg_pmh_pct: 'Avg. PM High',
  day0_pm_pullback_from_high_pct: 'PM Pullback from High',
  break_pmh: 'Break PM High',
  break_pmh_pct: 'Break',

  close_green_comp: 'Close > Prev. Close',
  close_red_comp: 'Close Red',
  open_level_green_comp: 'Close > Open',
  open_level_red_comp: 'Close < Open',
  avg_range_comp: 'Avg. Range',

  avg_pmh_comp: 'Avg. PMH',
  avg_pullback_pmh_comp: 'Avg. Pullback from PMH',
  break_pmh_comp: 'Break PMH',
  avg_pullback_from_high: 'PM Pullback from High',

  count: 'Count',
  high_low_comp: 'Avg. High/Low'
}


export const LABEL_SUPPLIMENTS = {
  'day0_avg_gap': { pre: '$' },
  'day0_avg_gap_pct': { post: '%' },
  'day0_close_gt_open_pct': { post: '%' },
  'day0_close_lt_open_pct': { post: '%' },
  'day0_high_spike': { post: '%' },
  'day0_low_spike': { post: '%' },
  'day0_avg_range': { pre: '$' },
  'day0_avg_range_pct': { post: '%' },
  'day1_close_gt_open_pct': { post: '%' },
  'day1_close_lt_open_pct': { post: '%' },
  'day1_close_gt_prev_close_pct': { post: '%' },
  'day1_close_lt_prev_close_pct': { post: '%' },
  'day1_high_spike': { post: '%' },
  'day1_low_spike': { post: '%' },
  'day1_avg_range': { pre: '$' },
  'day1_avg_range_pct': { post: '%' },
}


export const day0Cfg = [
  { metric: 'total_count', referenceKey: 'count' },
  { metric: ['day0_avg_gap', 'day0_avg_gap_pct'], referenceKey: 'avg_gap_comp' },
  { metric: ['day0_close_gt_open', 'day0_close_gt_open_pct'], referenceKey: 'close_gt_open_comp' },
  { metric: ['day0_close_lt_open', 'day0_close_lt_open_pct'], referenceKey: 'close_lt_open_comp' },
  { metric: 'day0_avg_high_time', referenceKey: 'avg_high_time', hardFormat: true },
  { metric: 'day0_avg_low_time', referenceKey: 'avg_low_time', hardFormat: true },
  { metric: ['day0_high_spike', 'day0_low_spike'], referenceKey: 'high_low_comp'},
  { metric: ['day0_avg_range', 'day0_avg_range_pct'], referenceKey: 'avg_range' },
  { metric: 'day0_avg_vol', referenceKey: 'avg_vol' }
]

export const day1Cfg = [
  { metric: 'day1_gap_up_3', referenceKey: 'gap_up_3' },
  { metric: 'day1_gap_down_3', referenceKey: 'gap_down_3' },
  { metric: ['day1_close_gt_open', 'day1_close_gt_open_pct'], referenceKey: 'close_gt_open_comp' },
  { metric: ['day1_close_lt_open', 'day1_close_lt_open_pct'], referenceKey: 'close_lt_open_comp' },
  { metric: ['day1_close_gt_prev_close', 'day1_close_gt_prev_close_pct'], referenceKey: 'close_gt_prev_close_comp' },
  { metric: ['day1_close_lt_prev_close', 'day1_close_lt_prev_close_pct'], referenceKey: 'close_lt_prev_close_comp' },
  { metric: ['day1_high_spike', 'day1_low_spike'], referenceKey: 'high_low_comp'},
  { metric: ['day1_avg_range', 'day1_avg_range_pct'], referenceKey: 'avg_range' },
  { metric: 'day1_avg_vol', referenceKey: 'avg_vol' }
]

