import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  makeStyles,
  Button,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  dialogRoot: {
    // backgroundColor: 'rgb(0,0,0)',
    // opacity: props => props.opacity,
    backgroundColor: 'transparent !important',
    '& .MuiBackdrop-root': {
      // backgroundColor: props 'rgba(0,0,0,$)',
      // opacity: props => props.opacity,
    },
    '& .MuiDialog-paperScrollPaper': {
      maxWidth: 'calc(100% - 64px)',
      maxHeight: 'calc(100% - 64px)',
      width: props => props.width,
      height: props => props.height,
    }
  }
}));


/**
 * Modal bound relative to parent, not necissarily full-screen
 * Parent must have position: relative!
 *
 * @component
 */
function ContainedModal({
  open,
  width,
  height,
  onClose,
  opacity,
  disablePortal,
  children
}) {
  const classes = useStyles({ width, height, opacity });

  return (
    <Dialog
      className={classes.dialogRoot}
      maxWidth="md"
      disableAutoFocus
      disableEnforceFocus
      disableScrollLock
      disablePortal={disablePortal}
      fullWidth
      transitionDuration={0}
      style={{ position: 'absolute' }}
      open={open}
      onClose={onClose}
    >
      {children}
    </Dialog>
  );
}


ContainedModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  opacity: PropTypes.number,
  children: PropTypes.any,
  disablePortal: PropTypes.bool,
};


ContainedModal.defaultProps = {
  open: false,
  width: 450,
  opacity: 0,
  height: 'unset',
  disablePortal: true
};

export default ContainedModal;



